import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'

//import Form from 'react-bootstrap/Form'
import { InputNumber, DatePicker, Form } from 'rsuite'
import isBefore from 'date-fns/isBefore'
import { format } from 'date-fns'

const JSONView = ({ formValue, formError }) => (
  <div className='row' style={{ marginBottom: 10 }}>
    <div class='col-8 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formValue</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class='col-4 json-tree-wrapper rs-panel'>
      <div aria-expanded='false' class='rs-panel-header' tabindex='-1'>
        <p class='rs-panel-title'>formError</p>
      </div>
      <div role='region' class='rs-panel-body'>
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
)
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
)

const formatDate = (pattern) => {
  return format(new Date(), pattern)
}


export default function FormulaBuilderRefillSection({
  formulaBuilderId,
  data,
  onRefillSectionChange,
  pracDetails,
  formulaDeatils,
  display_name,
  ApplyFormValidation,
  thisFormData,
  titleDisplayName
}) {

  let date_format = pracDetails.date_format ? pracDetails.date_format : 'y/M/d'
  date_format = date_format.replace('d', 'dd')
  date_format = date_format.replace('m', 'MM')
  date_format = date_format.replace('y', 'yy')
  date_format = date_format.replace('Y', 'yyyy')

  const [refill_no, setrefill_no] = useState('')
  const [refill_frequency, setrefill_frequency] = useState('') // flat,percentage
  const [refill_date, setrefill_date] = useState('')

  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'refill_no',
      name: 'Numbers of Refills',
      is_required: 0,
      key: 'refill_no',
      allow_zero: false,
    },
    {
      column: 'refill_frequency',
      name: 'Refills Frequency',
      is_required: 0,
      key: 'refill_frequency',
      allow_zero: false,
    },
    {
      column: 'refill_date',
      name: 'Refills Expiry',
      is_required: 0,
      key: 'refill_date',
      allow_zero: false,
    },
  ]

  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : []
  //console.log('prac_formula_setting', prac_formula_setting)
  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : []
  //console.log(current_formual_prac_setting.is_softgels)

  const [prac_allow_refills, set_prac_allow_refills] = useState(1)
  const [before_disabledDate, set_before_disabledDate] = useState(new Date())

  useEffect(() => {
    const today = new Date() // Replace this with your given date (e.g., '2023-07-27')
    // disable before dates
    var disabledDatem = new Date(today)
    disabledDatem.setDate(disabledDatem.getDate() - 1)
    set_before_disabledDate(disabledDatem)
    var prac_allow_refill = 0
    var prac_default_refill = 0
    var prac_default_refill_frequency = 0
    var prac_default_refill_expiry = 0

    const {
      allow_refills,
      default_refills,
      default_refills_frequency,
      default_refills_expiry,
    } = current_formual_prac_setting || {}

    // console.log(disabledDatem)
    if (current_formual_prac_setting) {
      prac_allow_refill = allow_refills ? allow_refills : 1

      prac_default_refill = default_refills ? default_refills : refill_no

      prac_default_refill_frequency = default_refills_frequency
        ? default_refills_frequency
        : refill_frequency

      if (!refill_date) {
        var prac_default_refill_expiry_days = default_refills_expiry
          ? default_refills_expiry
          : ''
        const today = new Date() // Replace this with your given date (e.g., '2023-07-27')
        // disable before dates
        var disabledDate = new Date(today)
        disabledDate.setDate(disabledDate.getDate() - 1)
        set_before_disabledDate(disabledDate)
        // get expiry date
        if (prac_default_refill_expiry_days) {
          prac_default_refill_expiry = new Date(today)
          prac_default_refill_expiry.setDate(
            prac_default_refill_expiry.getDate() +
            parseInt(prac_default_refill_expiry_days)
          )
        }
      }
    }
    // console.log('prac_refill1', prac_allow_refill, prac_default_refill, prac_default_refill_frequency, prac_default_refill_expiry, formulaBuilderId)

    if (formulaDeatils && formulaDeatils.id) {
      prac_default_refill =
        formulaDeatils.refill_days && formulaDeatils.refill_days != ''
          ? formulaDeatils.refill_days
          : prac_default_refill

      prac_default_refill_frequency =
        formulaDeatils.refill_frequency && formulaDeatils.refill_frequency != ''
          ? formulaDeatils.refill_frequency
          : prac_default_refill_frequency

      prac_default_refill_expiry =
        formulaDeatils.refill_end_date &&
          formulaDeatils.refill_end_date != '' &&
          formulaDeatils.refill_end_date != '0000-00-00 00:00:00'
          ? formulaDeatils.refill_end_date
          : prac_default_refill_expiry
      ////////////////////////
      if (
        prac_default_refill_expiry != '' &&
        prac_default_refill_expiry != '0000-00-00 00:00:00'
      ) {
        prac_default_refill_expiry = new Date(prac_default_refill_expiry)
        //const date = new Date(default_refills_expiry) // Replace this with your given date (e.g., '2023-07-27')
        //setrefill_date(date)
      }
    }
    set_prac_allow_refills(prac_allow_refill)

    setrefill_no(prac_default_refill)
    setrefill_frequency(prac_default_refill_frequency)
    setrefill_date(prac_default_refill_expiry)
  }, [formulaBuilderId, formulaDeatils])

  useEffect(() => {
    if (prac_allow_refills == 0) {
      onRefillSectionChange('', '', '')
    } else {
      onRefillSectionChange(refill_no, refill_frequency, refill_date)
    }
  }, [refill_no, refill_frequency, refill_date])

  useEffect(() => {
    formValidation()
  }, [refill_no, refill_frequency, refill_date])
  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation])

  const formValidation = () => {
    if (prac_allow_refills == 0) {
      formValue['refill_no'] = ''
      formValue['refill_frequency'] = ''
      formValue['refill_date'] = ''
    } else {
      formValue['refill_no'] = refill_no
      formValue['refill_frequency'] = refill_frequency
      formValue['refill_date'] = refill_date
    }

    var Final_array = []
    Object.values(requirements).map((i, key) => {
      if (
        (formValue['refill_no'] && formValue['refill_no'] != 0) ||
        (formValue['refill_date'] && formValue['refill_date'] != 0) ||
        (formValue['refill_frequency'] && formValue['refill_frequency'] != 0)
      ) {
        if (
          (i.key == 'refill_no' || i.key == 'refill_date') &&
          formValue['refill_no'] &&
          !formValue['refill_date']
        ) {
          i.is_required = 1
        }
        if (
          (i.key == 'refill_no' || i.key == 'refill_date') &&
          !formValue['refill_no'] &&
          formValue['refill_date']
        ) {
          i.is_required = 1
        }
        if (
          (i.key == 'refill_no' ||
            i.key == 'refill_date' ||
            i.key == 'refill_frequency') &&
          !formValue['refill_no'] &&
          !formValue['refill_date'] &&
          formValue['refill_frequency']
        ) {
          i.is_required = 1
        }
      }

      if (i.is_required == 1) {
        if (i.allow_zero == true) {
          if (
            String(formValue[i.key]).trim() == '' ||
            formValue[i.key] == null ||
            formValue[i.key] == undefined
          ) {
            // formError[i.key] = 'This field is required'
            formError[i.key] = `${i.name} is required.`
          } else {
            formError[i.key] = ''
          }
        } else {
          if (
            String(formValue[i.key]).trim() == '' ||
            formValue[i.key] == null ||
            formValue[i.key] == 0 ||
            formValue[i.key] == undefined
          ) {
            // formError[i.key] = 'This field is required'
            formError[i.key] = `${i.name} is required.`
          } else {
            formError[i.key] = ''
          }
        }
      } else {
        formError[i.key] = ''
      }
      var temp = {
        ...i,
        error: formError[i.key],
        value: formValue[i.key],
      }
      Final_array.push(temp)
    })

    //console.log('FORMULAS_REFILLS', Final_array)
    thisFormData(Final_array, 'FORMULAS_REFILLS')
  }
  if (prac_allow_refills == 0) {
    return <></>
  }
  const disableKeys = (event) => {
    // Allow numbers, dots, and specific key codes for control keys
    if (
      !(event.key === '.' || (event.key >= '0' && event.key <= '9')) &&
      ![8, 9, 37, 39, 46].includes(event.keyCode)
    ) {
      event.preventDefault()
    }
  }
  //console.log('disabled date before', before_disabledDate)

  return (
    <>
      <div className='row FormulaBuilderRefillSection'>
        <div className='col-12  '>
          <h2 className='title mt-5'>{display_name}</h2>
          <br />
        </div>

        <div className='col-8 '>
          <div className='col-md-12 col-sm-12 col-xs-12 p-t-5 p-b-20 formula_nm '>
            <div className=' formula_n p-l-10 p-r-10 p-t-20 -p-b-10 '>
              <div className='add_on_services formula_ingredients refill_settings_new formulaRefillSetting RefillSettingsMain'>
                <div className='col-md-12'>
                  <div className='group_inp'>
                    <div className='row mb-3'>
                      <div className='col-3'>Numbers of Refills </div>
                      <div className='col-3'>
                        <InputNumber
                          //className='form-control'
                          onChange={(e) => {
                            setrefill_no(e)
                          }}
                          value={String(refill_no)}
                          type='number'
                          //name='refill_no'
                          //placeholder='Numbers of Refills'
                          onKeyPress={disableKeys}
                        />
                      </div>
                      <div className='col-3'>
                        {formError['refill_no'] ? (
                          <>
                            <ErrorMessage>
                              {formError['refill_no']}
                            </ErrorMessage>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='group_inp'>
                    <div className='row mb-3'>
                      <div className='col-3'>Refills Frequency </div>
                      <div className='col-3'>
                        <InputNumber
                          //className='form-control'
                          onChange={(e) => {
                            setrefill_frequency(e)
                          }}
                          value={String(refill_frequency)}
                          type='number'
                          //name='refill_frequency'
                          //placeholder='Refills Frequency'
                          onKeyPress={disableKeys}
                        />
                      </div>
                      <div className='col-3'>
                        {formError['refill_frequency'] ? (
                          <>
                            <ErrorMessage>
                              {formError['refill_frequency']}
                            </ErrorMessage>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='group_inp'>
                    <div className='row'>
                      <div className='col-3  '>Refills Expiry</div>
                      <div className='col-3 RefillsExpiry'>
                        <DatePicker
                          style={{ width: '100%' }}
                          oneTap
                          //disabled
                          //format='yyyy-MM-dd' // manual
                          format={(date_format)} // format according to Prac Website setting
                          value={refill_date != null && refill_date != undefined && refill_date != 0 ? refill_date : ''}
                          //defaultValue={refill_date != null && refill_date != undefined &&  refill_date!=0 ? refill_date : new Date()}
                          // disabledDate={(date) =>
                          //   isBefore(date, before_disabledDate)
                          // }
                          shouldDisableDate={date => isBefore(date, new Date())}
                          //limitStartYear={date => new Date()}
                          onChange={(e) => {
                            e == null || e == undefined
                              ? setrefill_date('')
                              : setrefill_date(e)
                          }}
                        />

                        {/* <button onClick={() => setValue(null)} className="ClearButton">clear</button> */}
                      </div>
                      <div className='col-3'>
                        {formError['refill_date'] ? (
                          <>
                            <ErrorMessage>
                              {formError['refill_date']}
                            </ErrorMessage>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-4'>
          {/*<JSONView formValue={formValue} formError={formError} />*/}
        </div>
      </div>
    </>
  )
}
