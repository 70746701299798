import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Swal from "sweetalert2";

import InputSelect from "../../../helpers/inputSelect";
import {
  Checkbox,
  SelectPicker,
  Tooltip,
  Whisper,
  InputNumber,
  InputPicker,
  InputGroup,
  Dropdown,
  Popover,
  Input,
  Form,
  Icon,
  Button,
  IconButton,
  Notification,
  Stack,
} from "rsuite";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import PageNextIcon from "@rsuite/icons/PageNext";

import _ from "lodash";
import he from "he";

const JSONView = ({ formValue, formError }) => (
  <div className="row" style={{ marginBottom: 10 }}>
    <div class="col-6 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formValue</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class="col-6 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formError</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
);
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
);

const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), "text/html");
  return doc.documentElement.textContent;
};

export default function FormulaBuilderOptionsSection({
  fontFamily,
  formulaBuilderId,
  formulaDosagesType,
  data,
  numberDays,
  onNumberDaysChange,
  onNumberBagsChange,
  onOptionsSectionChange,
  activeDosages,
  pracDetails,
  onActiveDosageDaysChange,
  formulaDosagesSetting = [],
  formulaDeatils,
  updateDefaultMeasuremt,
  updateIngredientSelection,
  ingredients,
  display_name,
  formulaOptionCheck,
  activeOptionDosagesData,
  ApplyFormValidation,
  thisFormData,
  setConcentrates, // return ingredient_selection
  formulaTotalQty,
  dosageConverting,
  dosageId,
  titleDisplayName
}) {
  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : [];
  const [ingredientSelections, setIngredientSelections] = useState([]);

  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : [];

  const delayedSetTotalQty = _.debounce((value) => {
    setTotalQty(value);
    // Add your additional logic or function calls here
  }, 500); // Adjust the delay time as needed

  const handleInputChange = (e) => {
    const value = e.target.value;
    delayedSetTotalQty(value);
  };
  {
    /* <ArrowDownLineIcon /> */
  }
  const include_ingredients =
    data.selectedIngrediuents != null && data.selectedIngrediuents
      ? JSON.parse(data.selectedIngrediuents)
      : []; //selected_ingredients

  const measurement_options_arr = data.measurment_type_list
    ? data.measurment_type_list
    : []; //all_measurement_options

  const [defaultMeasuremt, setdefaultMeasuremt] = useState(
    data.default_measuremt_type || 0
  );
  const [defaultMeasuremtName, setdefaultMeasuremtName] = useState("");
  const [measurementOptions, setmeasurementOptions] = useState([]);
  const [allow_multiple_measurements, setallow_multiple_measurements] =
    useState(false);

  const [totalQty, setTotalQty] = useState(null);
  useEffect(() => {
    formulaTotalQty(totalQty);
  }, [totalQty]);
  const [numberBags, setNumberBags] = useState(null);
  // const [numberDays, setNumberDays] = useState(numberDays);
  const [totalQtyOptions, setTotalQtyOptions] = useState([]);
  const [containerOptions, setContainerOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);

  const [allowCapsule, setallowCapsule] = useState(0);
  const [is_capsules, set_is_capsules] = useState(
    data.is_capsules ? data.is_capsules : 0
  );

  const [minBasedQty, setMinBasedQty] = useState(0);
  const [maxBasedQty, setMaxBasedQty] = useState(0);

  const [isCapsuleSelected, setIsCapsuleSelected] = useState(0);
  const [capsuleSizeSelected, setCapsuleSizeSelected] = useState(0);
  const [capsuleSizeOptions, setcapsuleSizeOptions] = useState([]);

  const [is_tablets, set_is_tablets] = useState(
    data.is_tablets ? data.is_tablets : 0
  );
  const [isTabletSelected, setIsTabletSelected] = useState(0);
  const [tabletSizeSelected, setTabletSizeSelected] = useState(0);
  const [tabletSizeOptions, settabletSizeOptions] = useState([]);

  const [is_softgels, set_is_softgels] = useState(
    data.is_softgels ? data.is_softgels : 0
  );
  const [isSoftgelSelected, setIsSoftgelSelected] = useState(0);
  const [softgelSizeSelected, setSoftgelSizeSelected] = useState(0);
  const [softgelSizeOptions, setsoftgelSizeOptions] = useState([]);

  const is_base_ingredient = data.is_base_ingredient
    ? data.is_base_ingredient
    : 0;
  const baseIngredientOptionsList = data.all_base_ingredients
    ? data.all_base_ingredients
    : [];
  const baseIngredientOptions = baseIngredientOptionsList
    .slice()
    .sort((a, b) => a.position - b.position);

  const [isBaseIngSelected, setIsBaseIngSelected] = useState(0);
  const [baseIngSizeSelected, setBaseIngSizeSelected] = useState(0);
  const [baseIngSizeSelectedArr, setBaseIngSizeSelectedArr] = useState([]);

  const [baseIngSizeOptions, setBaseIngSizeOptions] = useState([]);
  // const [activeDosages, setActiveDosages] = useState(""); // if capsule/tablets/softgel active then get Dosage Inst. according to this

  const [options, setoptions] = useState([]);

  const [prac_default_capsule, set_prac_default_capsule] = useState(null);
  const [prac_default_softgels, set_prac_default_softgels] = useState(null);
  const [prac_default_tablets, set_prac_default_tablets] = useState(null);
  //const [prac_default_totalQty, set_prac_default_totalQty] = useState(null)
  const single_measurement_selection = [
    "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE",
    "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES",
  ];

  const [ingredient_selection, setingredient_selection] = useState([]);
  //const [, set_selected_ingredients] = useState([])
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});
  var requirements = [
    {
      column: "capsules",
      name: "Capsules",
      is_required: 0,
      key: "capsules",
      allow_zero: false,
    },
    {
      column: "tablets",
      name: "Tablets",
      is_required: 0,
      key: "tablets",
      allow_zero: false,
    },
    {
      column: "softgels",
      name: "Softgels",
      is_required: 0,
      key: "softgels",
      allow_zero: false,
    },
    // {
    //   column: 'allow_capsules',
    //   name: 'Allow Capsules',
    //   is_required: 0,
    //   key: 'allow_capsules',
    //   allow_zero: false,
    // },
    // {
    //   column: 'allow_tablets',
    //   name: 'Allow Tablets',
    //   is_required: 0,
    //   key: 'allow_tablets',
    //   allow_zero: false,
    // },
    // {
    //   column: 'allow_softgels',
    //   name: 'Allow Softgels',
    //   is_required: 0,
    //   key: 'allow_softgels',
    //   allow_zero: false,
    // },
    {
      column: "base_ingredient",
      name: "Base Ingredients",
      is_required: 0,
      key: "base_ingredient",
      allow_zero: false,
    },
    {
      column: "min_qty",
      name: "Min Qty",
      is_required: 0,
      key: "min_qty",
      allow_zero: false,
    },
    {
      column: "max_qty",
      name: "Max Qty",
      is_required: 0,
      key: "max_qty",
      allow_zero: false,
    },
    {
      column: "active_option_dosages",
      name: "Option Section Dosages Type",
      is_required: 0,
      key: "active_option_dosages",
      allow_zero: false,
    },
    {
      column: "active_option_dosages_array",
      name: "Option Section Dosages Type Array",
      is_required: 0,
      key: "active_option_dosages_array",
      allow_zero: false,
    },
    {
      column: "selection_type_ingredients",
      name: "Selection type Allow Ingredients Type & Measurements",
      is_required: 0,
      key: "selection_type_ingredients",
      allow_zero: false,
    },
    {
      column: "ingredient_selection_type",
      name: "Ingredient Selection type ",
      is_required: 0,
      key: "selection_type_ingredients",
      allow_zero: false,
    },
    {
      column: "allow_multiple_measurement_types_in_same_formula",
      name: "Ingredient Selection type ",
      is_required: 0,
      key: "allow_multiple_measurement_types_in_same_formula",
      allow_zero: false,
    },
    {
      column: "allow_ingredients",
      name: "Allow Ingredients & Measurements",
      is_required: 0,
      key: "allow_ingredients",
      allow_zero: false,
    },
    {
      column: "dosages_mode_setting",
      name: "Active Dosages Mode Setting",
      is_required: 0,
      key: "dosages_mode_setting",
      allow_zero: false,
    },
  ];

  if (formulaDosagesSetting && formulaDosagesSetting.length != 0) {
    if (
      formulaDosagesSetting.no_of_container &&
      formulaDosagesSetting.no_of_container != 0
    ) {
      var name = formulaDosagesSetting.no_of_container_name
        ? formulaDosagesSetting.no_of_container_name
        : "Number of Bags";
      var temp = {
        column: "no_of_container",
        name: name,
        is_required: formulaDosagesSetting.no_of_container_required,
        key: "no_of_container",
        allow_zero: false,
      };
      requirements.push(temp);
    } else {
      var name = formulaDosagesSetting.no_of_container_name
        ? formulaDosagesSetting.no_of_container_name
        : "Number of Bags";
      var temp = {
        column: "no_of_container",
        name: name,
        is_required: 0,
        key: "no_of_container",
        allow_zero: false,
      };
      requirements.push(temp);
    }
    if (
      formulaDosagesSetting.no_of_days &&
      formulaDosagesSetting.no_of_days != 0
    ) {
      var name = formulaDosagesSetting.no_of_days_name
        ? formulaDosagesSetting.no_of_days_name
        : "Number of Bags";
      var temp = {
        column: "no_of_days",
        name: name,
        is_required: formulaDosagesSetting.no_of_days_required,
        key: "no_of_days",
        allow_zero: false,
      };
      requirements.push(temp);
    } else {
      var name = formulaDosagesSetting.no_of_days_name
        ? formulaDosagesSetting.no_of_days_name
        : "Number of Bags";
      var temp = {
        column: "no_of_days",
        name: name,
        is_required: 0,
        key: "no_of_days",
        allow_zero: false,
      };
      requirements.push(temp);
    }
    if (
      formulaDosagesSetting.weight_volume &&
      formulaDosagesSetting.weight_volume != 0
    ) {
      var name = formulaDosagesSetting.weight_volume_name
        ? formulaDosagesSetting.weight_volume_name
        : "Total Quantity";
      var is_required = 0;
      if (
        (data.ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
          data.allow_multiple_measurement_types_in_same_formula == "1") ||
        (data.ingredient_selection_type ==
          "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" &&
          data.selectedIngrediuents &&
          JSON.parse(data.selectedIngrediuents)[0]
            ?.allow_multiple_measurement_types_in_same_formula == "1")
      ) {
        is_required = 0;
      } else {
        is_required = formulaDosagesSetting.weight_volume_required;
      }
      var temp = {
        column: "weight_volume",
        name: name,
        is_required: is_required, //formulaDosagesSetting.weight_volume_required,
        key: "weight_volume",
        allow_zero: false,
      };
      requirements.push(temp);
    } else {
      var name = formulaDosagesSetting.weight_volume_name
        ? formulaDosagesSetting.weight_volume_name
        : "Total Quantity";
      var temp = {
        column: "weight_volume",
        name: name,
        is_required: 0,
        key: "weight_volume",
        allow_zero: false,
      };
      requirements.push(temp);
    }
  }

  useEffect(() => {
    if (measurement_options_arr.length !== 0) {
      // Create a regular expression to match a comma
      const commaRegex = /,/;
      var select_measurements = [];
      var all_select_measurements = [];
      var final_measurements = [];
      Object.entries(include_ingredients).map(([key, value1]) => {
        var thisVAl = value1.measurement;
        if (
          single_measurement_selection.includes(
            data.ingredient_selection_type
          ) &&
          select_measurements.length == 0
        ) {
          // Check if the text matches the regular expression.
          const hasComma = commaRegex.test(thisVAl);
          setdefaultMeasuremt(value1.default_measurement);
          if (
            data.ingredient_selection_type ==
            "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES"
          ) {
            var is_allow =
              value1.allow_multiple_measurement_types_in_same_formula == 1
                ? true
                : false;
            setallow_multiple_measurements(is_allow);
          }
          if (hasComma === true) {
            var arr = thisVAl.split(",");
            var this_measurement = [];
            arr.map((val) => {
              const foundMeasurement = measurement_options_arr.find(
                (measurement) => measurement.id == val
              );
              if (foundMeasurement) {
                this_measurement.push(foundMeasurement);
              }
            });
            value1["this_measurement"] = this_measurement;
            select_measurements = value1;
          } else {
            const foundMeasurement = measurement_options_arr.find(
              (measurement) => measurement.id == thisVAl
            );
            var this_measurement = [];
            if (foundMeasurement) {
              this_measurement.push(foundMeasurement);
            }
            value1["this_measurement"] = this_measurement;
            select_measurements = value1;
          }
        } else if (
          single_measurement_selection.includes(data.ingredient_selection_type)
        ) {
          // do nothing
        } else if (
          data.ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
        ) {
          //select_measurements.push(data.default_measuremt_type)
          thisVAl = data.default_measuremt_type
            ? data.default_measuremt_type
            : "";
          const foundMeasurement = measurement_options_arr.find(
            (measurement) => measurement.id == thisVAl
          );
          var this_measurement = [];
          if (foundMeasurement) {
            this_measurement.push(foundMeasurement);
          }
          value1["this_measurement"] = this_measurement;
          select_measurements.push(value1);
        } else if (
          data.ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT"
        ) {
          var this_measurement = value1.measurement;
          var arr = thisVAl.split(",");
          var this_measurement = [];
          arr.map((val) => {
            const foundMeasurement = measurement_options_arr.find(
              (measurement) => measurement.id == val
            );
            if (foundMeasurement) {
              this_measurement.push(foundMeasurement);
            }
          });
          value1["this_measurement"] = this_measurement;

          select_measurements.push(value1);
        } else {
          //select_measurements.push(value1.measurement)
          const foundMeasurement = measurement_options_arr.find(
            (measurement) => measurement.id == thisVAl
          );
          var this_measurement = [];
          if (foundMeasurement) {
            this_measurement.push(foundMeasurement);
          }
          value1["this_measurement"] = this_measurement;
          select_measurements.push(value1);
        }
      });
      var selected_ingredients = [];
      if (
        single_measurement_selection.includes(data.ingredient_selection_type)
      ) {
        selected_ingredients.push(select_measurements.ingredient_id);
      } else {
        select_measurements.map((option) => {
          selected_ingredients.push(option.ingredient_id);
        });
      }
      setingredient_selection(selected_ingredients);
      setConcentrates(selected_ingredients);
      setmeasurementOptions(select_measurements);
    }

    //##############################################

    var capsuleOptions =
      data.capsuleAllDataWithOptions != null
        ? JSON.parse(data.capsuleAllDataWithOptions)
        : [];
    var tabletOptions =
      data.tabletsAllDataWithOptions != null
        ? JSON.parse(data.tabletsAllDataWithOptions)
        : [];
    var softgelOptions =
      data.softgelsAllDataWithOptions != null
        ? JSON.parse(data.softgelsAllDataWithOptions)
        : [];
    /////////////////////////////////////
    setcapsuleSizeOptions(capsuleOptions);
    settabletSizeOptions(tabletOptions);
    setsoftgelSizeOptions(softgelOptions);
  }, [formulaBuilderId]);

  // Update data according to Prac Formula Setting
  useEffect(() => {
    setoptions(
      data.baseIngredientOptions != null
        ? JSON.parse(data.baseIngredientOptions)
        : []
    );
    var base_option =
      data.baseIngredientOptions != null
        ? JSON.parse(data.baseIngredientOptions)
        : [];
    setBaseIngSizeOptions(base_option);
    //console.log('checlBase', baseIngSizeSelected)
    if (
      baseIngredientOptions != "" &&
      !baseIngSizeSelected &&
      data.base_ingredient_required == 1
    ) {
      baseIngredientOptions.map((i, ks) => {
        if (ks == "0" && baseIngSizeSelected == 0) {
          //console.log('select ist option in baseIng.')

          setIsBaseIngSelected(1);
          setBaseIngSizeSelected(i.id);
          setMinBasedQty(i.weight_from);
          setMaxBasedQty(i.weight_to);
          setBaseIngSizeSelectedArr(i);
          onActiveDosageDaysChange("");
          setIsCapsuleSelected(0);
          setIsSoftgelSelected(0);
          setIsTabletSelected(0);
        }
      });
    } else if (baseIngredientOptions != "" && baseIngSizeSelected != 0) {
      //console.log('checlBase2')
      setIsBaseIngSelected(1);
      onActiveDosageDaysChange("");
      setIsCapsuleSelected(0);
      setIsSoftgelSelected(0);
      setIsTabletSelected(0);
    }
    //console.log('baseIngSizeSelectedArr', baseIngSizeSelectedArr)

    var selected_dosages_mode_key = "";
    var days = Number(0);
    var bags = 0;
    var total_qty = 0;

    var allow_capsules = 0;
    var allow_softgels = 0;
    var allow_tablets = 0;

    var default_capsules_option = 0;
    var default_softgels_option = 0;
    var default_tablets_option = 0;

    const {
      formula_option,
      default_dosages_mode,
      default_capsule,
      default_softgels,
      default_tablets,
    } = current_formual_prac_setting || {};

    if (current_formual_prac_setting) {
      allow_capsules =
        current_formual_prac_setting && current_formual_prac_setting.is_capsule
          ? current_formual_prac_setting.is_capsule
          : is_capsules;

      allow_softgels =
        current_formual_prac_setting && current_formual_prac_setting.is_softgels
          ? current_formual_prac_setting.is_softgels
          : is_softgels;

      allow_tablets =
        current_formual_prac_setting && current_formual_prac_setting.is_tablets
          ? current_formual_prac_setting.is_tablets
          : is_tablets;

      set_is_capsules(allow_capsules);
      set_is_softgels(allow_softgels);
      set_is_tablets(allow_tablets);

      default_capsules_option = default_capsule
        ? default_capsule
        : default_capsules_option;
      default_softgels_option = default_softgels
        ? default_softgels
        : default_softgels_option;
      default_tablets_option = default_tablets
        ? default_tablets
        : default_tablets_option;

      // set_prac_default_capsule(default_capsules_option)
      // set_prac_default_softgels(default_softgels_option)
      // set_prac_default_tablets(default_tablets_option)
    }
    if (formulaDosagesSetting) {
      selected_dosages_mode_key = formulaDosagesSetting.key_value;
      if (
        formulaDosagesSetting.no_of_container_default_value != "" &&
        formulaDosagesSetting.no_of_container_default_value != undefined &&
        formulaDosagesSetting.no_of_container_default_value != 0 &&
        formulaDosagesSetting.no_of_container == 1
      ) {
        bags = formulaDosagesSetting.no_of_container_default_value;
        //setNumberBags(formulaDosagesSetting.no_of_container_default_value)
      }
      if (
        formulaDosagesSetting.no_of_days_default_value != "" &&
        formulaDosagesSetting.no_of_days_default_value != undefined &&
        formulaDosagesSetting.no_of_days_default_value != 0 &&
        formulaDosagesSetting.no_of_days == 1
      ) {
        days = formulaDosagesSetting.no_of_days_default_value;
        //onNumberDaysChange(formulaDosagesSetting.no_of_days_default_value)
      }
      if (
        formulaDosagesSetting.weight_volume_default_value != "" &&
        formulaDosagesSetting.weight_volume_default_value != undefined &&
        formulaDosagesSetting.weight_volume_default_value != 0 &&
        formulaDosagesSetting.weight_volume == 1 &&
        (totalQty == "" || totalQty == 0)
      ) {
        total_qty = formulaDosagesSetting.weight_volume_default_value;
        //setTotalQty(formulaDosagesSetting.weight_volume_default_value)
      }
    }
    var prac_formula_option_setting = formula_option
      ? JSON.parse(formula_option)
      : [];

    if (
      prac_formula_option_setting &&
      prac_formula_option_setting[selected_dosages_mode_key] != "" &&
      prac_formula_option_setting[selected_dosages_mode_key] != null &&
      prac_formula_option_setting[selected_dosages_mode_key] != undefined
    ) {
      var selected_option_setting =
        prac_formula_option_setting[selected_dosages_mode_key];
      const {
        default_bags,
        default_days,
        default_ingredient_rows,
        default_total_qty_received,
      } = selected_option_setting || {};

      bags = default_bags ? default_bags : bags;
      days = default_bags ? default_days : days;
      total_qty = default_total_qty_received
        ? default_total_qty_received
        : total_qty;
    }

    if (
      formulaDeatils &&
      formulaDeatils.id &&
      formulaDeatils.formula_t &&
      formulaDeatils.formula_t == dosageId
    ) {
      days =
        formulaDeatils.dosage_noof_days &&
        formulaDeatils.dosage_noof_days != 0 &&
        formulaDeatils.dosage_noof_days != undefined
          ? formulaDeatils.dosage_noof_days
          : days;

      bags =
        formulaDeatils.dosage_quantity &&
        formulaDeatils.dosage_quantity != 0 &&
        formulaDeatils.dosage_quantity != undefined
          ? formulaDeatils.dosage_quantity
          : bags;

      total_qty =
        formulaDeatils.set_totalweight_formula &&
        formulaDeatils.set_totalweight_formula != 0 &&
        formulaDeatils.set_totalweight_formula != undefined
          ? formulaDeatils.set_totalweight_formula
          : total_qty;

      total_qty = parseInt(total_qty) || "";
      var formual_base_ingredient =
        formulaDeatils && formulaDeatils.base_ingredient
          ? formulaDeatils.base_ingredient
          : "";
      setTimeout(() => {
        console.log(
          "baseIngredientOptions",
          baseIngredientOptions,
          baseIngredientOptions.lenght
        );
        var baseIngredientOptionsArr = data.all_base_ingredients
          ? data.all_base_ingredients
          : [];
        if (
          formual_base_ingredient &&
          is_base_ingredient == 1 &&
          baseIngredientOptions &&
          baseIngredientOptionsList.lenght > 0 &&
          baseIngredientOptionsList &&
          baseIngredientOptionsArr &&
          baseIngredientOptionsArr.lenght > 0
        ) {
          //console.log('formulaDetails if')
          setBaseIngSizeSelected(formual_base_ingredient);
          var selectedOption = baseIngredientOptions.find(
            (item) => item.id == formual_base_ingredient
          );
          // console.log(
          //   'selectedOptionBase',
          //   formual_base_ingredient,
          //   formulaDeatils.base_ingredient,
          //   selectedOption
          // )
          setTimeout(() => {
            onActiveDosageDaysChange("");
            setIsCapsuleSelected(0);
            setIsSoftgelSelected(0);
            setIsTabletSelected(0);
            setBaseIngSizeSelected(formual_base_ingredient);
            setBaseIngSizeSelectedArr(selectedOption);
          }, 500);
        } else {
          //console.log('formulaDetails else')
          var formula_is_capsules =
            formulaDeatils && formulaDeatils.is_capsule
              ? formulaDeatils.is_capsule
              : 0;

          if (formula_is_capsules == 1) {
            set_prac_default_capsule(formulaDeatils?.capsule_size);
            onActiveDosageDaysChange("capsules");
            setIsCapsuleSelected(1);

            default_capsules_option =
              formulaDeatils.capsule_size &&
              formulaDeatils.capsule_size != 0 &&
              formulaDeatils.capsule_size != undefined
                ? formulaDeatils.capsule_size
                : default_capsules_option;
            // setTimeout(() => {
            //setCapsuleSizeSelected(formulaDeatils?.capsule_size)
            // }, 500)
            setTimeout(() => {
              set_prac_default_capsule(formulaDeatils?.capsule_size);
              onActiveDosageDaysChange("capsules");
              setIsCapsuleSelected(1);
              setCapsuleSizeSelected(default_capsules_option);
              var thisOption = JSON.parse(data.capsuleAllDataWithOptions);
              var selectedOption = thisOption.find(
                (item) => item.id == default_capsules_option
              );
              setBaseIngSizeSelectedArr(selectedOption);
            }, 700);
          }
          var formula_is_softgels =
            formulaDeatils && formulaDeatils.is_softgel
              ? formulaDeatils.is_softgel
              : 0;
          if (formula_is_softgels == 1) {
            set_prac_default_softgels(formulaDeatils?.capsule_size);
            setIsSoftgelSelected(1);
            onActiveDosageDaysChange("softgels");
            default_softgels_option =
              formulaDeatils.capsule_size &&
              formulaDeatils.capsule_size != 0 &&
              formulaDeatils.capsule_size != undefined
                ? formulaDeatils.capsule_size
                : default_softgels_option;
            // setTimeout(() => {
            //   setSoftgelSizeSelected(formulaDeatils?.capsule_size)
            // }, 500)
            setTimeout(() => {
              set_prac_default_softgels(formulaDeatils?.capsule_size);
              setIsSoftgelSelected(1);
              onActiveDosageDaysChange("softgels");
              setSoftgelSizeSelected(default_softgels_option);
              var thisOption = JSON.parse(data.softgelsAllDataWithOptions);
              var selectedOption = thisOption.find(
                (item) => item.id == default_softgels_option
              );
              setBaseIngSizeSelectedArr(selectedOption);
            }, 700);
          }

          var formula_is_tablets =
            formulaDeatils && formulaDeatils.is_tablet
              ? formulaDeatils.is_tablet
              : 0;
          if (formula_is_tablets == 1) {
            set_prac_default_tablets(formulaDeatils?.capsule_size);
            onActiveDosageDaysChange("tablets");
            setIsTabletSelected(1);

            default_tablets_option =
              formulaDeatils.capsule_size &&
              formulaDeatils.capsule_size != 0 &&
              formulaDeatils.capsule_size != undefined
                ? formulaDeatils.capsule_size
                : default_tablets_option;
            // setTimeout(() => {
            //   setTabletSizeSelected(formulaDeatils?.capsule_size)
            // }, 500)
            setTimeout(() => {
              set_prac_default_tablets(formulaDeatils?.capsule_size);
              onActiveDosageDaysChange("tablets");
              setIsTabletSelected(1);
              //set_prac_default_tablets(default_tablets_option)
              setTabletSizeSelected(default_tablets_option);
              var thisOption = JSON.parse(data.tabletsAllDataWithOptions);
              var selectedOption = thisOption.find(
                (item) => item.id == default_tablets_option
              );
              setBaseIngSizeSelectedArr(selectedOption);
            }, 500);
          }
        }

        onNumberDaysChange(days);
        setNumberBags(bags);
      }, 700);
      ///////////////////////////////////

      setTotalQty(total_qty);
    }

    if (dosageConverting == true) {
      days = numberDays ? numberDays : days;
      bags = numberBags ? numberBags : bags;
      total_qty = totalQty ? totalQty : total_qty;
    }
    onNumberDaysChange(days);
    setNumberBags(bags);
    setTotalQty(total_qty); //console.log('setTotalQty-2 - ' + total_qty)

    set_prac_default_capsule(default_capsules_option);
    set_prac_default_softgels(default_softgels_option);
    set_prac_default_tablets(default_tablets_option);
  }, [formulaBuilderId, formulaDosagesType]);

  useEffect(() => {
    if (
      is_base_ingredient == 1 &&
      baseIngredientOptions != "" &&
      baseIngSizeSelected > 0
    ) {
      setIsTabletSelected(0);
      setIsSoftgelSelected(0);
      setIsCapsuleSelected(0);
      setIsBaseIngSelected(1);
      onActiveDosageDaysChange("");
    } else {
      if (activeDosages == "capsules") {
        setIsTabletSelected(0);
        setIsSoftgelSelected(0);
        setIsBaseIngSelected(0);
        setIsCapsuleSelected(1);
      } else if (activeDosages == "tablets") {
        //setallowCapsule(1)
        setIsCapsuleSelected(0);
        setIsSoftgelSelected(0);
        setIsBaseIngSelected(0);
        setIsTabletSelected(1);
      } else if (activeDosages == "softgels") {
        setIsCapsuleSelected(0);
        setIsTabletSelected(0);
        setIsBaseIngSelected(0);
        setIsSoftgelSelected(1);
      } else {
        setIsCapsuleSelected(0);
        setIsTabletSelected(0);
        setIsSoftgelSelected(0);
        setIsBaseIngSelected(0);
        setMaxBasedQty(0);
        setMinBasedQty(0);
        onActiveDosageDaysChange("");
      }
    }

    if (
      isBaseIngSelected == 1 &&
      baseIngSizeSelected != "" &&
      baseIngSizeSelected != null
    ) {
      onOptionsSectionChange(
        totalQty,
        isBaseIngSelected,
        baseIngSizeSelected,
        minBasedQty,
        maxBasedQty
      );
      onActiveDosageDaysChange("");
    } else if (isCapsuleSelected == 1) {
      onOptionsSectionChange(
        totalQty,
        isCapsuleSelected,
        capsuleSizeSelected,
        minBasedQty,
        maxBasedQty
      );
    } else if (isTabletSelected == 1) {
      onOptionsSectionChange(
        totalQty,
        isTabletSelected,
        tabletSizeSelected,
        minBasedQty,
        maxBasedQty
      );
    } else if (isSoftgelSelected == 1) {
      onOptionsSectionChange(
        totalQty,
        isSoftgelSelected,
        softgelSizeSelected,
        minBasedQty,
        maxBasedQty
      );
    } else {
      setCapsuleSizeSelected(0);
      setTabletSizeSelected(0);
      setSoftgelSizeSelected(0);
      onActiveDosageDaysChange("");
      onOptionsSectionChange(totalQty, 0, 0, minBasedQty, maxBasedQty);
    }
    formValidation();
  }, [
    allowCapsule,
    totalQty,
    activeDosages,
    isCapsuleSelected,
    isTabletSelected,
    isSoftgelSelected,
    isBaseIngSelected,
    baseIngSizeSelected,
    capsuleSizeSelected,
    minBasedQty,
    maxBasedQty,
    numberDays,
    numberBags,
  ]);

  useEffect(() => {
    if (numberDays == 0 || numberDays == "") {
      if (formulaDosagesSetting.no_of_days_required == 1) {
        onNumberDaysChange(1);
      } else {
        //onNumberDaysChange(numberDays)
      }
    }

    if (numberBags == 0 || numberBags == "") {
      if (formulaDosagesSetting.no_of_container_required == 1) {
        onNumberBagsChange(1);
      } else {
        onNumberBagsChange(numberBags);
      }
      //
      //setNumberBags(1)
      //onNumberBagsChange(1)
    } else {
      onNumberBagsChange(numberBags);
    }
  }, [numberDays, numberBags]);

  useEffect(() => {
    updateDefaultMeasuremt(
      defaultMeasuremt,
      defaultMeasuremtName,
      measurementOptions,
      allow_multiple_measurements
    );
    formValidation();
  }, [
    defaultMeasuremt,
    defaultMeasuremtName,
    measurementOptions,
    allow_multiple_measurements,
    measurementOptions,
    ingredient_selection,
  ]);

  const handleSelectChange = (e, field) => {
    const value = e; //e.target.value
    // Do something with the selected value
    const func = eval(field); // evaluate the string as a function

    func(value); // call the function with the value
  };

  useEffect(() => {
    const is_capsules = data.is_capsules ? data.is_capsules : 0;
    const is_tablets = data.is_tablets ? data.is_tablets : 0;
    const is_softgels = data.is_softgels ? data.is_softgels : 0;
    const is_base_ingredient = data.is_base_ingredient
      ? data.is_base_ingredient
      : 0;
    const baseIngredientOptions = data.all_base_ingredients
      ? data.all_base_ingredients
      : [];
    // setActiveDosages("");

    if (activeDosages == "capsules") {
      if (
        is_capsules == 0 ||
        data.capsuleAllDataWithOptions == "" ||
        data.capsuleAllDataWithOptions == null
      ) {
        setIsCapsuleSelected(0);
        onActiveDosageDaysChange("");
        setCapsuleSizeSelected(0);
      }
    } else if (activeDosages == "tablets") {
      if (
        is_tablets == 0 ||
        data.tabletsAllDataWithOptions == "" ||
        data.tabletsAllDataWithOptions == null
      ) {
        setIsTabletSelected(0);
        onActiveDosageDaysChange("");
        setTabletSizeSelected(0);
      }
    } else if (
      activeDosages == "softgels" ||
      data.softgelsAllDataWithOptions == "" ||
      data.softgelsAllDataWithOptions == null
    ) {
      if (is_softgels == 0) {
        setIsSoftgelSelected(0);
        onActiveDosageDaysChange("");
        setSoftgelSizeSelected(0);
      }
    } else {
      onActiveDosageDaysChange("");
    }
    // if (activeDosages == 'softgels') {
    //   if (is_base_ingredient == 0) {
    //     setIsSoftgelSelected(0)
    //     onActiveDosageDaysChange('')
    //   }
    // }
    // setCapsuleSizeSelected(0)
    // setTabletSizeSelected(0)
    // setSoftgelSizeSelected(0)

    // var perTotalQtyOptions =
    //   data.ingredients_weight_volume_value != null
    //     ? JSON.parse(data.ingredients_weight_volume_value)
    //     : []
    /*if (formulaDosagesSetting.length !== 0) {
      // no_of_container_options
      var no_of_container_optionsTemp =
        formulaDosagesSetting.no_of_container_options != null &&
        formulaDosagesSetting.no_of_container_options != ''
          ? JSON.parse(formulaDosagesSetting.no_of_container_options)
          : []
     
      var no_of_container_options =
        Object.values(no_of_container_optionsTemp) &&
        Object.values(no_of_container_optionsTemp).length != 0
          ? Object.values(no_of_container_optionsTemp).map((item) => ({
              label: item['value'],
              value: String(item['value']),
              position: Number(item['position']),
            }))
          : []
      // no_of_days_options
      var no_of_days_optionsTemp =
        formulaDosagesSetting.no_of_days_options != null &&
        formulaDosagesSetting.no_of_days_options != ''
          ? JSON.parse(formulaDosagesSetting.no_of_days_options)
          : []
      var no_of_days_options =
        Object.values(no_of_days_optionsTemp) &&
        Object.values(no_of_days_optionsTemp).length != 0
          ? Object.values(no_of_days_optionsTemp).map((item) => ({
              label: item['value'],
              value: String(item['value']),
              position: Number(item['position']),
            }))
          : []
      // Total Qty
      var weight_volume_optionsTemp =
        formulaDosagesSetting.weight_volume_options != null &&
        formulaDosagesSetting.weight_volume_options != ''
          ? JSON.parse(formulaDosagesSetting.weight_volume_options)
          : []
      var weight_volume_options =
        Object.values(weight_volume_optionsTemp) &&
        Object.values(weight_volume_optionsTemp).length != 0
          ? Object.values(weight_volume_optionsTemp).map((item) => ({
              label: item['value'],
              value: String(item['value']),
              position: Number(item['position']),
            }))
          : []
      // update options
      setTotalQtyOptions(weight_volume_options)
      setContainerOptions(no_of_container_options)
      setDayOptions(no_of_days_options)
    }*/
    if (formulaDosagesSetting.length !== 0) {
      // no_of_container_options
      var no_of_container_optionsTemp =
        formulaDosagesSetting.no_of_container_options != null &&
        formulaDosagesSetting.no_of_container_options !== ""
          ? JSON.parse(formulaDosagesSetting.no_of_container_options)
          : [];

      var no_of_container_options =
        no_of_container_optionsTemp &&
        typeof no_of_container_optionsTemp === "object" &&
        Object.values(no_of_container_optionsTemp).length !== 0
          ? Object.values(no_of_container_optionsTemp).map((item) => ({
              label: item["value"],
              value: String(item["value"]),
              position: Number(item["position"]),
            }))
          : [];

      // no_of_days_options
      var no_of_days_optionsTemp =
        formulaDosagesSetting.no_of_days_options != null &&
        formulaDosagesSetting.no_of_days_options !== ""
          ? JSON.parse(formulaDosagesSetting.no_of_days_options)
          : [];
      var no_of_days_options =
        no_of_days_optionsTemp &&
        typeof no_of_days_optionsTemp === "object" &&
        Object.values(no_of_days_optionsTemp).length !== 0
          ? Object.values(no_of_days_optionsTemp).map((item) => ({
              label: item["value"],
              value: String(item["value"]),
              position: Number(item["position"]),
            }))
          : [];

      // Total Qty
      var weight_volume_optionsTemp =
        formulaDosagesSetting.weight_volume_options != null &&
        formulaDosagesSetting.weight_volume_options !== ""
          ? JSON.parse(formulaDosagesSetting.weight_volume_options)
          : [];
      var weight_volume_options =
        weight_volume_optionsTemp &&
        typeof weight_volume_optionsTemp === "object" &&
        Object.values(weight_volume_optionsTemp).length !== 0
          ? Object.values(weight_volume_optionsTemp).map((item) => ({
              label: item["value"],
              value: String(item["value"]),
              position: Number(item["position"]),
            }))
          : [];

      // update options
      setTotalQtyOptions(weight_volume_options);
      setContainerOptions(no_of_container_options);
      setDayOptions(no_of_days_options);
      // default value
    }
    activeOptionDosagesData(baseIngSizeSelectedArr);
  }, [formulaBuilderId, data, numberDays, activeDosages]);

  useEffect(() => {
    setMinBasedQty(minBasedQty);
    setMaxBasedQty(maxBasedQty);
  }, [minBasedQty, maxBasedQty]);

  useEffect(() => {}, [activeDosages]);

  function renderMeasurementOptions() {
    if (
      measurementOptions == "" ||
      measurementOptions.length == 0 ||
      measurementOptions == undefined
    ) {
      return (
        <label className="col-form-label">
          <i>No Ingredient Selection</i>
        </label>
      );
    }

    if (
      data.ingredient_selection_type ===
      "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES"
    ) {
      const this_measurement = measurementOptions.this_measurement
        ? measurementOptions.this_measurement
        : [];
      return (
        <>
          <div
            className="col "
            style={{ paddingRight: "5px", display: "inline-flex" }}
          >
            <span
              className="text-right"
              style={{ paddingRight: "6px", paddingTop: "7px" }}
            >
              {measurementOptions.ingredient_name}
            </span>
            <SelectPicker
              size="xl"
              data={this_measurement}
              placement="auto"
              onChange={(value) => {
                manageDefaultMeasurement(
                  value,
                  measurementOptions.ingredient_id
                );
              }}
              value={String(measurementOptions.default_measurement)}
              labelKey={"display_name"}
              valueKey={"id"}
              cleanable={false}
              searchable={false}
              style={{ float: "right", minWidth: "100px" }}
              renderValue={(value, item) => {
                return item ? item.display_name : "";
              }}
            />
          </div>
        </>
      );
    } else if (
      data.ingredient_selection_type ===
      "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT"
    ) {
      return Object.entries(measurementOptions).map(([key, value1]) => {
        const this_measurement = value1.this_measurement;
        return (
          <div className="row" key={key}>
            <span className="col-10 text-left" style={{ width: "85%" }}>
              <Checkbox
                onChange={(e, checked) => {
                  manage_ingredient_selection(checked, value1.ingredient_id);
                }}
                defaultChecked
                value="1"
                style={{ marginLeft: "-8px" }}
                checked={ingredient_selection.includes(value1.ingredient_id)}
              >
                {value1.ingredient_name}
              </Checkbox>
            </span>
            <div
              className="col-10 pull-left"
              style={{ paddingRight: "5px", marginLeft: "25px" }} //width: '20%'
            >
              <SelectPicker
                size="xl"
                data={this_measurement}
                placement="auto"
                onChange={(value) => {
                  manageDefaultMeasurement(value, value1.ingredient_id);
                }}
                value={String(value1.default_measurement)}
                labelKey={"display_name"} //label_name
                valueKey={"id"}
                cleanable={false}
                searchable={false}
                // renderValue={(value, item) => {
                //   return item ? item.display_name : ''
                // }}
              />
            </div>
          </div>
        );
      });
    } else if (
      data.ingredient_selection_type ===
      "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT"
    ) {
      return Object.entries(measurementOptions).map(([key, value1]) => {
        const this_measurement = value1.this_measurement;
        return (
          <div className="row">
            <span className="col-5 text-right">
              <Checkbox
                onChange={async (e, checked) => {
                  console.log("Checkbox", e, checked);
                  // const checked = e.target.checked
                  await manage_ingredient_selection(
                    checked,
                    value1.ingredient_id
                  );
                }}
                defaultChecked
                //value='1'
                style={{ marginLeft: "-8px" }}
              >
                {value1.ingredient_name} ({this_measurement[0].display_name})
              </Checkbox>
            </span>
          </div>
        );
      });
    } else if (
      data.ingredient_selection_type ===
      "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE"
    ) {
      return measurementOptions.this_measurement.map((value1, k) => (
        <div className=" col-form-label">
          <span>
            {" "}
            {measurementOptions.ingredient_name} ({value1.display_name})
          </span>
        </div>
      ));
    } else if (
      data.ingredient_selection_type ==
      "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
    ) {
      return Object.entries(measurementOptions).map(([key, value1]) => {
        const this_measurement = value1.this_measurement;
        //if (key == 0) {
        return (
          <div className="row">
            <span
              className="col-4 text-left"
              style={{ width: "100%", marginRight: "0.9rem" }}
            >
              {value1.ingredient_name} ({this_measurement[0].display_name})
            </span>
            {/* <div
              className="col-4 pull-right"
              style={{ paddingRight: "5px", width: "67%" }}
            >
              {this_measurement[0].display_name} ({this_measurement[0].name})
            </div> */}
          </div>
        );
        //}
      });
    }
    return null;
  }

  function manageDefaultMeasurementMain(value, ingredient_id) {
    if (single_measurement_selection.includes(data.ingredient_selection_type)) {
      measurementOptions.default_measurement = value;

      setmeasurementOptions(measurementOptions);
    } else {
      const updatedArr = measurementOptions.map((val) =>
        val.ingredient_id == ingredient_id
          ? { ...val, default_measurement: value }
          : val
      );

      setmeasurementOptions(updatedArr);
    }
  }
  function manageDefaultMeasurement(value, ingredient_id) {
    const updatedOptions = { ...measurementOptions }; // Create a copy of the object

    if (single_measurement_selection.includes(data.ingredient_selection_type)) {
      updatedOptions.default_measurement = value; // Update the default_measurement property
      setmeasurementOptions(updatedOptions);
    } else {
      const updatedArr = measurementOptions.map((val) =>
        val.ingredient_id == ingredient_id
          ? { ...val, default_measurement: value }
          : val
      );

      setmeasurementOptions(updatedArr);
    }
  }

  function renderIngredientOptions() {
    if (measurementOptions == "" || measurementOptions.length == 0) {
      return <i>No Ingredient Selection</i>;
    }
    if (single_measurement_selection.includes(data.ingredient_selection_type)) {
      return <>{measurementOptions.ingredient_name}</>;
    } else {
      return Object.entries(measurementOptions).map(([key, value1]) => (
        <div className="col-3" key={key}>
          <Checkbox
            onChange={async (e) => {
              const checked = e.target.checked;
              await manage_ingredient_selection(checked, value1.ingredient_id);
            }}
            defaultChecked
            value="1"
            style={{ marginLeft: "-8px" }}
          >
            {value1.ingredient_name}
          </Checkbox>
        </div>
      ));
    }
  }
  function manage_ingredient_selectionMain(checked, ingredient_id) {
    setingredient_selection((prev) =>
      checked == true
        ? [...prev, ingredient_id]
        : prev.filter((val) => val !== ingredient_id)
    );
    const formulaOption = {
      status: checked,
      ingredient_id: ingredient_id,
    };
  }
  const manage_ingredient_selection = async (
    checked,
    ingredient_id,
    forceDisable = false
  ) => {
    if (forceDisable == false) {
      let filteredIngredients = ingredients.filter(
        (item) => item.ingredient_id !== ""
      );

      let groupedIngredients = filteredIngredients.reduce((groups, item) => {
        const ingId = item.ing_id;
        if (ingId === ingredient_id) {
          if (!groups[ingId]) {
            groups[ingId] = [];
          }
          groups[ingId].push(item);
        }
        return groups;
      }, {});
      let groupLength = groupedIngredients[ingredient_id]
        ? groupedIngredients[ingredient_id].length
        : 0;

      if (groupLength !== 0 && !checked) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to remove this ingredient from the ingredient table?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
          cancelButtonText: "Cancel",
        });

        if (!result.isConfirmed) {
          return;
        }
      }
    }

    setingredient_selection((prev) =>
      checked
        ? [...prev, ingredient_id]
        : prev.filter((val) => val !== ingredient_id)
    );

    const formulaOption = {
      status: checked,
      ingredient_id: ingredient_id,
    };

    formulaOptionCheck(formulaOption);
  };

  useEffect(() => {
    updateIngredientSelection(ingredient_selection);
  }, [ingredient_selection]);

  useEffect(() => {
    if (ingredients && ingredients.length != 0) {
      let filteredIngredientsArr = ingredients.filter(
        (item) => item.ingredient_id !== ""
      );

      if (
        data.ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
        data.allow_multiple_measurement_types_in_same_formula != "1" &&
        filteredIngredientsArr.length != 0
      ) {
        var firstMeasurement =
          filteredIngredientsArr[0]["ing_default_measurment_id"];
        Object.entries(measurementOptions).map(([key, option]) => {
          const this_measurement = option.this_measurement;
          if (this_measurement && this_measurement.length != 0) {
            const thisIngredientsMeasurementsArr = this_measurement.map(
              (measurement) => measurement.id
            );
            const hasFirstMeasurement =
              thisIngredientsMeasurementsArr.includes(firstMeasurement);
            if (
              !hasFirstMeasurement &&
              ingredient_selection &&
              ingredient_selection.includes(option.ingredient_id)
            ) {
              manage_ingredient_selection(false, option.ingredient_id, true); //forcely disable this ingredient type
            }
          }
        });
      }
      if (
        data.ingredient_selection_type ==
          "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
        data.allow_multiple_measurement_types_in_same_formula != "1"
      ) {
        if (measurementOptions) {
          Object.entries(measurementOptions).map(([key, value1]) => {
            const this_measurement = value1?.this_measurement;
            const this_ingredient_id = value1?.ingredient_id;
            const firstMeasurement =
              filteredIngredientsArr && filteredIngredientsArr.length != 0
                ? filteredIngredientsArr[0]["default_measurment"]
                : "";
            const first_ingredient_id =
              filteredIngredientsArr && filteredIngredientsArr.length != 0
                ? filteredIngredientsArr[0]["ing_id"]
                : "";

            if (
              firstMeasurement &&
              this_measurement &&
              this_measurement != firstMeasurement &&
              this_ingredient_id &&
              this_ingredient_id == first_ingredient_id
            ) {
              manageDefaultMeasurement(firstMeasurement, this_ingredient_id);
            } else {
              console.error(
                "updateDefaultMeasuremt-is_same",
                measurementOptions,
                this_measurement,
                firstMeasurement
              );
            }
          });
        } else {
          console.error("updateDefaultMeasuremt-else");
        }
      } else if (
        data.ingredient_selection_type ==
          "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" &&
        data.allow_multiple_measurement_types_in_same_formula != 1
      ) {
        if (measurementOptions) {
          const this_measurement = measurementOptions?.default_measurement;
          const this_ingredient_id = measurementOptions?.ingredient_id;
          const firstMeasurement =
            filteredIngredientsArr && filteredIngredientsArr.length !== 0
              ? filteredIngredientsArr[0]["default_measurment"]
              : "";
          if (
            firstMeasurement &&
            this_measurement &&
            this_measurement != firstMeasurement &&
            this_ingredient_id
          ) {
            manageDefaultMeasurement(firstMeasurement, this_ingredient_id);
          } else {
            console.error(
              "updateDefaultMeasuremt-is_same",
              measurementOptions,
              this_measurement,
              firstMeasurement
            );
          }
        } else {
          console.error(
            "updateDefaultMeasuremt-no_measurementOptions",
            measurementOptions
          );
        }
      } else {
        console.error("updateDefaultMeasuremt-else");
      }
    }
  }, [ingredients]);

  function groupIngredientsByColumn(ingredients, column = "ing_id") {
    var result = {};
    ingredients.forEach((array) => {
      const field = array[column];
      if (array.ingredient_id != "") {
        var tempArray = {};
        tempArray.id = array.id;
        tempArray.ingredient_id = array.ingredient_id;
        tempArray.ing_id = array.ing_id;
        //tempArray.id = array.id

        if (!result[field]) {
          // If the key doesn't exist in the result object, create it
          result[field] = [];
        }
        // Push the ingredient into the appropriate group
        result[field].push(tempArray);
      }
    });

    return result;
  }

  const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);
  // Total Qty
  const totalQtyDropDownder = (
    { onClose, left, top, className },
    ref,
    array
  ) => {
    const handleSelect = (eventKey) => {
      onClose();
      setTotalQty(eventKey);
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {totalQtyOptions.map((option) => (
            <Dropdown.Item key={option.position} eventKey={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };
  // Numbers of Days
  const numberDaysDropDown = (
    { onClose, left, top, className },
    ref,
    array
  ) => {
    const handleSelect = (eventKey) => {
      onClose();
      onNumberDaysChange(eventKey);
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {dayOptions.map((option) => (
            <Dropdown.Item key={option.position} eventKey={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };
  // Numbers of container
  const numberContainerDropDown = (
    { onClose, left, top, className },
    ref,
    array
  ) => {
    const handleSelect = (eventKey) => {
      onClose();
      handleSelectChange(eventKey, "setNumberBags");
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {containerOptions.map((option) => (
            <Dropdown.Item key={option.position} eventKey={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };

  const disableKeys = (event) => {
    // Allow numbers, dots, and specific key codes for control keys
    if (
      !(event.key === "." || (event.key >= "0" && event.key <= "9")) &&
      ![8, 9, 37, 39, 46].includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  function default_measurement() {
    if (
      measurementOptions &&
      measurementOptions.this_measurement &&
      measurementOptions.this_measurement.length != 0
    ) {
      return measurementOptions["this_measurement"][0]["display_name"] + " ";
    } else {
    }
  }

  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation]);

  useEffect(() => {
    if (dosageConverting == true) {
      DosagesConverting();
    }
  }, [dosageConverting]);

  const formValidation = () => {
    formValue["min_qty"] = minBasedQty;
    formValue["max_qty"] = maxBasedQty;
    formValue["weight_volume"] = totalQty;
    formValue["no_of_container"] = numberBags;
    formValue["no_of_days"] = numberDays;
    formValue["base_ingredient"] = baseIngSizeSelected;
    formValue["capsules"] = capsuleSizeSelected;
    formValue["softgels"] = softgelSizeSelected;
    formValue["tablets"] = tabletSizeSelected;
    formValue["active_option_dosages"] = activeDosages;
    formValue["allow_ingredients"] = ingredient_selection;
    formValue["selection_type_ingredients"] = measurementOptions;
    formValue["ingredient_selection_type"] = data.ingredient_selection_type;
    formValue["allow_multiple_measurement_types_in_same_formula"] =
      allow_multiple_measurements;
    formValue["dosages_mode_setting"] = formulaDosagesSetting;
    var baseIngSizeSelectedArr = {};

    if (
      is_base_ingredient == 1 &&
      baseIngredientOptions != "" &&
      isBaseIngSelected == 1 &&
      baseIngSizeSelected &&
      activeDosages == "" &&
      baseIngredientOptions != ""
    ) {
      var selectedOption = baseIngredientOptions.find(
        (item) => item.id == baseIngSizeSelected
      );
      if (selectedOption) {
        baseIngSizeSelectedArr = selectedOption;
      }
    } else if (activeDosages == "capsules") {
      var selectedOption = Object.values(capsuleSizeOptions).find(
        (item) => item.id == capsuleSizeSelected
      );
      if (selectedOption) {
        baseIngSizeSelectedArr = selectedOption;
      }
    } else if (activeDosages == "tablets") {
      var selectedOption = Object.values(tabletSizeOptions).find(
        (item) => item.id == tabletSizeSelected
      );
      if (selectedOption) {
        baseIngSizeSelectedArr = selectedOption;
      }
    } else if (activeDosages == "softgels") {
      var selectedOption = Object.values(softgelSizeOptions).find(
        (item) => item.id == softgelSizeSelected
      );
      if (selectedOption) {
        baseIngSizeSelectedArr = selectedOption;
      }
    }
    formValue["active_option_dosages_array"] = baseIngSizeSelectedArr;
    var Final_array = [];
    Object.values(requirements).map((i, key) => {
      if (i.is_required == 1) {
        if (i.allow_zero == true) {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = "This field is required";
          } else {
            formError[i.key] = "";
          }
        } else {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == 0 ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = "This field is required";
          } else {
            formError[i.key] = "";
          }
        }
      } else {
        formError[i.key] = "";
      }
      var temp = {
        ...i,
        is_required: i.is_required,
        error: formError[i.key],
        value: formValue[i.key],
      };
      Final_array.push(temp);
    });
    thisFormData(Final_array, "FORMULAS_OPTIONS");
  };

  const DosagesConverting = () => {
    console.log(
      "Dosages-Converting",
      formulaDosagesType,
      activeDosages,
      formulaDosagesSetting,
      {
        numberDays: numberDays,
        totalQty: totalQty,
        numberBags: numberBags,
      }
    );
  };

  return (
    <>
      <div className="FormulaBuilderOptionsSection">
        <h2 className="title mt-5">{display_name}</h2>

        <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="col-8">
            <div className="row mb-3">
              <label for="" className="col-sm-4 col-form-label">
                Ingredients & Measurements
              </label>
              <div className="col-sm-8" style={{ display: "inline-flex" }}>
                {renderMeasurementOptions()}
              </div>
            </div>

            {/* Base Ingredients section */}
            {is_base_ingredient == 1 && baseIngredientOptions != "" && (
              <>
                {/* Assuming 'searchable' and 'cleanable' are intended to be used somewhere else */}
                {(() => {
                  var searchable = true;
                  var cleanable = false;
                  var optionValue = "id";
                  var optionLabel = "display_name";
                  // Initialize optionData as an empty array
                  var optionData = [];
                  var optionDataIdd = [];
                  // Populate optionData array
                  optionData = Object.values(baseIngredientOptions)
                    ? Object.values(baseIngredientOptions).map((item) => ({
                        label: item[optionLabel],
                        value: String(item[optionValue]),
                        array: item,
                      }))
                    : [];
                  // Conditionally add "No Base Ingredient" option
                  if (data.base_ingredient_required != 1) {
                    optionData.unshift({
                      label: "No Base Ingredient",
                      value: "0",
                      array: null,
                    });
                  }

                  var selectedBaseingredient = null;
                  if (baseIngSizeSelected) {
                    const foundItem = baseIngredientOptions.find(
                      (item) => item.id == baseIngSizeSelected
                    );
                    //console.log(foundItem)
                    if (foundItem) {
                      selectedBaseingredient = foundItem;
                    }
                  }
                  optionDataIdd = optionData.map((item) => item.value);
                  return (
                    <>
                      <div className="row mb-3">
                        <label for="" className="col-sm-3 col-form-label">
                          Base Ingredient{" "}
                          {data.base_ingredient_required == 1 && (
                            <span className="required">*</span>
                          )}
                        </label>
                        <div
                          className="col-sm-3"
                          style={{ display: "inline-flex" }}
                        >
                          <SelectPicker
                            data={optionData}
                            placeholder={"Choose Base Ingredients"}
                            defaultValue={String(baseIngSizeSelected)}
                            searchable={searchable}
                            onChange={(e, event) => {
                              setBaseIngSizeSelected(e);
                              const foundItem = baseIngredientOptions.find(
                                (item) => item.id == e
                              );
                              if (foundItem) {
                                setMinBasedQty(foundItem["weight_from"]);
                                setMaxBasedQty(foundItem["weight_to"]);
                                setBaseIngSizeSelectedArr(foundItem);
                              } else {
                                setMinBasedQty(0);
                                setMaxBasedQty(0);
                                setBaseIngSizeSelectedArr([]);
                              }
                            }}
                            style={{ width: "100%" }}
                            cleanable={cleanable}
                            labelKey={"label"}
                            valueKey={"value"}
                            value={String(baseIngSizeSelected)}
                            renderMenuItem={(label, item) => {
                              return (
                                <div
                                  key={item.value}
                                  style={fontFamily} // Add fontFamily here
                                >
                                  {label}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      {selectedBaseingredient &&
                        selectedBaseingredient.text != "" && (
                          <>
                            <div className="row mb-3">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              ></label>
                              <div
                                className="col-sm-9"
                                style={{ display: "inline-flex" }}
                              >
                                <span className="weight_range_text">
                                  {selectedBaseingredient &&
                                  selectedBaseingredient.text
                                    ? selectedBaseingredient.text
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  );
                })()}
              </>
            )}

            {/* Capsules,Tablets, and softgels section */}
            {is_base_ingredient == 0 || baseIngredientOptions == "" ? (
              <>
                {/* Capsules */}
                {data.capsuleAllDataWithOptions != "" &&
                data.capsuleAllDataWithOptions != null ? (
                  <>
                    {is_capsules == 1 && data.is_capsules == 1 ? (
                      <>
                        {/* Assuming 'searchable' and 'cleanable' are intended to be used somewhere else */}
                        {(() => {
                          var searchable = false;
                          var cleanable = false;
                          // Initialize optionData as an empty array
                          var optionData = [
                            { value: "1", label: "Yes" },
                            { value: "0", label: "No" },
                          ];
                          var capsuleOptionData = [];
                          capsuleOptionData = Object.values(capsuleSizeOptions)
                            ? Object.values(capsuleSizeOptions).map((item) => ({
                                label:
                                  item["capsule_name"] + " - " + item["name"],
                                value: String(item["id"]),
                                array: item,
                              }))
                            : [];

                          return (
                            <>
                              <div className="row mb-3">
                                <label
                                  for=""
                                  className="col-sm-3 col-form-label"
                                >
                                  Capsules
                                </label>
                                <div
                                  className="col-sm-3"
                                  style={{ display: "inline-flex" }}
                                >
                                  <SelectPicker
                                    data={optionData}
                                    placeholder={"Choose Capsules"}
                                    defaultValue={String(isCapsuleSelected)}
                                    searchable={false}
                                    onChange={(e, event) => {
                                      if (e == 1) {
                                        setIsCapsuleSelected(e);
                                        onActiveDosageDaysChange("capsules");
                                        var thisOption = JSON.parse(
                                          data.capsuleAllDataWithOptions
                                        );
                                        const thisOptionIdArr = thisOption.map(
                                          (optin) => optin.id
                                        );
                                        thisOption.map((i, k) => {
                                          if (
                                            prac_default_capsule != null &&
                                            thisOptionIdArr.includes(
                                              prac_default_capsule
                                            )
                                          ) {
                                            if (i.id == prac_default_capsule) {
                                              setCapsuleSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          } else {
                                            if (k == 0) {
                                              setCapsuleSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          }
                                        });
                                      } else {
                                        if (activeDosages == "capsules") {
                                          setIsCapsuleSelected(e);
                                          setMinBasedQty(0);
                                          setMaxBasedQty(0);
                                          onActiveDosageDaysChange("");
                                          setBaseIngSizeSelectedArr([]);
                                        }
                                      }
                                    }}
                                    style={{ width: "100%" }}
                                    cleanable={false}
                                    value={String(isCapsuleSelected)}
                                  />
                                </div>
                                {is_capsules == 1 && isCapsuleSelected == 1 && (
                                  <>
                                    <label
                                      for=""
                                      className="col-sm-2 col-form-label text-right"
                                    >
                                      Capsules Option
                                    </label>
                                    <div className="col-sm-3">
                                      <SelectPicker
                                        data={capsuleOptionData}
                                        placeholder={"Choose Capsules Size"}
                                        defaultValue={String(
                                          capsuleSizeSelected
                                        )}
                                        searchable={searchable}
                                        onChange={(e, event) => {
                                          setCapsuleSizeSelected(e);
                                          const foundItem =
                                            capsuleSizeOptions.find(
                                              (item) => item.id == e
                                            );

                                          if (foundItem) {
                                            setMinBasedQty(
                                              foundItem["weight_from"]
                                            );
                                            setMaxBasedQty(
                                              foundItem["weight_to"]
                                            );
                                            setBaseIngSizeSelectedArr(
                                              foundItem
                                            );
                                          } else {
                                            setMinBasedQty(0);
                                            setMaxBasedQty(0);
                                            setBaseIngSizeSelectedArr([]);
                                          }
                                        }}
                                        style={{ width: "100%" }}
                                        cleanable={cleanable}
                                        value={String(capsuleSizeSelected)}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              {baseIngSizeSelectedArr &&
                                is_capsules == 1 &&
                                isCapsuleSelected == 1 &&
                                baseIngSizeSelectedArr.text != "" && (
                                  <>
                                    <div className="row mb-3">
                                      <label
                                        htmlFor=""
                                        className="col-sm-3 col-form-label"
                                      ></label>
                                      <div
                                        className="col-sm-9"
                                        style={{ display: "inline-flex" }}
                                      >
                                        <span className="weight_range_text">
                                          {baseIngSizeSelectedArr.text}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          );
                        })()}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* Tablets */}
                {data.tabletsAllDataWithOptions != "" &&
                data.tabletsAllDataWithOptions != null ? (
                  <>
                    {is_tablets == 1 && data.is_tablets == 1 ? (
                      <>
                        {/* Assuming 'searchable' and 'cleanable' are intended to be used somewhere else */}
                        {(() => {
                          var searchable = false;
                          var cleanable = false;
                          // Initialize optionData as an empty array
                          var optionData = [
                            { value: "1", label: "Yes" },
                            { value: "0", label: "No" },
                          ];
                          var tabletOptionData = [];
                          tabletOptionData = Object.values(tabletSizeOptions)
                            ? Object.values(tabletSizeOptions).map((item) => ({
                                label:
                                  item["capsule_name"] + " - " + item["name"],
                                value: String(item["id"]),
                                array: item,
                              }))
                            : [];

                          return (
                            <>
                              <div className="row mb-3">
                                <label
                                  for=""
                                  className="col-sm-3 col-form-label"
                                >
                                  Tablets
                                </label>
                                <div
                                  className="col-sm-3"
                                  style={{ display: "inline-flex" }}
                                >
                                  <SelectPicker
                                    data={optionData}
                                    placeholder={"Choose Tablets"}
                                    defaultValue={String(isTabletSelected)}
                                    searchable={false}
                                    onChange={(e, event) => {
                                      if (e == 1) {
                                        setIsTabletSelected(e);
                                        onActiveDosageDaysChange("tablets");
                                        var thisOption = JSON.parse(
                                          data.tabletsAllDataWithOptions
                                        );
                                        const thisOptionIdArr = thisOption.map(
                                          (optin) => optin.id
                                        );
                                        thisOption.map((i, k) => {
                                          if (
                                            prac_default_tablets != null &&
                                            thisOptionIdArr.includes(
                                              prac_default_tablets
                                            )
                                          ) {
                                            if (i.id == prac_default_tablets) {
                                              setTabletSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          } else {
                                            if (k == 0) {
                                              setTabletSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          }
                                        });
                                      } else {
                                        if (activeDosages == "tablets") {
                                          setIsTabletSelected(e);
                                          setMinBasedQty(0);
                                          setMaxBasedQty(0);
                                          onActiveDosageDaysChange("");
                                          setBaseIngSizeSelectedArr([]);
                                        }
                                      }
                                    }}
                                    style={{ width: "100%" }}
                                    cleanable={false}
                                    value={String(isTabletSelected)}
                                  />
                                </div>
                                {is_tablets == 1 && isTabletSelected == 1 && (
                                  <>
                                    <label
                                      for=""
                                      className="col-sm-2 col-form-label text-right"
                                    >
                                      Tablets Option
                                    </label>
                                    <div className="col-sm-3">
                                      <SelectPicker
                                        data={tabletOptionData}
                                        placeholder={"Choose Tablets Size"}
                                        defaultValue={String(
                                          tabletSizeSelected
                                        )}
                                        searchable={searchable}
                                        onChange={(e, event) => {
                                          setTabletSizeSelected(e);
                                          const foundItem =
                                            tabletSizeOptions.find(
                                              (item) => item.id == e
                                            );

                                          if (foundItem) {
                                            setMinBasedQty(
                                              foundItem["weight_from"]
                                            );
                                            setMaxBasedQty(
                                              foundItem["weight_to"]
                                            );
                                            setBaseIngSizeSelectedArr(
                                              foundItem
                                            );
                                          } else {
                                            setMinBasedQty(0);
                                            setMaxBasedQty(0);
                                            setBaseIngSizeSelectedArr([]);
                                          }
                                        }}
                                        style={{ width: "100%" }}
                                        cleanable={cleanable}
                                        value={String(tabletSizeSelected)}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              {baseIngSizeSelectedArr &&
                                is_tablets == 1 &&
                                isTabletSelected == 1 &&
                                baseIngSizeSelectedArr.text != "" && (
                                  <>
                                    <div className="row mb-3">
                                      <label
                                        htmlFor=""
                                        className="col-sm-3 col-form-label"
                                      ></label>
                                      <div
                                        className="col-sm-9"
                                        style={{ display: "inline-flex" }}
                                      >
                                        <span className="weight_range_text">
                                          {baseIngSizeSelectedArr.text}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          );
                        })()}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* softgels */}
                {data.softgelsAllDataWithOptions != "" &&
                data.softgelsAllDataWithOptions != null ? (
                  <>
                    {is_softgels == 1 && data.is_softgels == 1 ? (
                      <>
                        {/* Assuming 'searchable' and 'cleanable' are intended to be used somewhere else */}
                        {(() => {
                          var searchable = false;
                          var cleanable = false;
                          // Initialize optionData as an empty array
                          var optionData = [
                            { value: "1", label: "Yes" },
                            { value: "0", label: "No" },
                          ];
                          var softgelOptionData = [];
                          softgelOptionData = Object.values(softgelSizeOptions)
                            ? Object.values(softgelSizeOptions).map((item) => ({
                                label:
                                  item["capsule_name"] + " - " + item["name"],
                                value: String(item["id"]),
                                array: item,
                              }))
                            : [];

                          return (
                            <>
                              <div className="row mb-3">
                                <label
                                  for=""
                                  className="col-sm-3 col-form-label"
                                >
                                  Softgels
                                </label>
                                <div
                                  className="col-sm-3"
                                  style={{ display: "inline-flex" }}
                                >
                                  <SelectPicker
                                    data={optionData}
                                    placeholder={"Choose Softgels"}
                                    defaultValue={String(isSoftgelSelected)}
                                    searchable={false}
                                    onChange={(e, event) => {
                                      if (e == 1) {
                                        setIsSoftgelSelected(e);
                                        onActiveDosageDaysChange("softgels");
                                        var thisOption = JSON.parse(
                                          data.softgelsAllDataWithOptions
                                        );
                                        const thisOptionIdArr = thisOption.map(
                                          (optin) => optin.id
                                        );
                                        thisOption.map((i, k) => {
                                          if (
                                            prac_default_softgels != null &&
                                            thisOptionIdArr.includes(
                                              prac_default_softgels
                                            )
                                          ) {
                                            if (i.id == prac_default_softgels) {
                                              setSoftgelSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);

                                              setBaseIngSizeSelectedArr(i);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          } else {
                                            if (k == 0) {
                                              setSoftgelSizeSelected(i.id);
                                              setMinBasedQty(i.weight_from);
                                              setMaxBasedQty(i.weight_to);
                                              setBaseIngSizeSelectedArr(i);
                                            }
                                          }
                                        });
                                      } else {
                                        if (activeDosages == "softgels") {
                                          setIsSoftgelSelected(e);
                                          setMinBasedQty(0);
                                          setMaxBasedQty(0);
                                          onActiveDosageDaysChange("");
                                          setBaseIngSizeSelectedArr([]);
                                        }
                                      }
                                    }}
                                    style={{ width: "100%" }}
                                    cleanable={false}
                                    value={String(isSoftgelSelected)}
                                  />
                                </div>
                                {is_softgels == 1 && isSoftgelSelected == 1 && (
                                  <>
                                    <label
                                      for=""
                                      className="col-sm-2 col-form-label text-right"
                                    >
                                      Softgels Option
                                    </label>
                                    <div className="col-sm-3">
                                      <SelectPicker
                                        data={softgelOptionData}
                                        placeholder={"Choose Softgels Size"}
                                        defaultValue={String(
                                          softgelSizeSelected
                                        )}
                                        searchable={searchable}
                                        onChange={(e, event) => {
                                          setSoftgelSizeSelected(e);
                                          const foundItem =
                                            softgelSizeOptions.find(
                                              (item) => item.id == e
                                            );

                                          if (foundItem) {
                                            setMinBasedQty(
                                              foundItem["weight_from"]
                                            );
                                            setMaxBasedQty(
                                              foundItem["weight_to"]
                                            );
                                            setBaseIngSizeSelectedArr(
                                              foundItem
                                            );
                                          } else {
                                            setMinBasedQty(0);
                                            setMaxBasedQty(0);
                                            setBaseIngSizeSelectedArr([]);
                                          }
                                        }}
                                        style={{ width: "100%" }}
                                        cleanable={cleanable}
                                        value={String(softgelSizeSelected)}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              {baseIngSizeSelectedArr &&
                                is_softgels == 1 &&
                                isSoftgelSelected == 1 &&
                                baseIngSizeSelectedArr.text != "" && (
                                  <>
                                    <div className="row mb-3">
                                      <label
                                        htmlFor=""
                                        className="col-sm-3 col-form-label"
                                      ></label>
                                      <div
                                        className="col-sm-9"
                                        style={{ display: "inline-flex" }}
                                      >
                                        <span className="weight_range_text">
                                          {baseIngSizeSelectedArr.text}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          );
                        })()}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* numbers of Conatainers */}
            {formulaDosagesSetting &&
              formulaDosagesSetting.no_of_container &&
              formulaDosagesSetting.no_of_container != 0 &&
              (() => {
                var name = formulaDosagesSetting.no_of_container_name
                  ? formulaDosagesSetting.no_of_container_name
                  : "Choose Number of Bags";

                return (
                  <div className="row mb-3">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      {decodeEntities(name)}
                      {formulaDosagesSetting.no_of_container_required == 1 && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <div className="col-sm-3">
                      {formulaDosagesSetting.no_of_container_allow_user_to_any_value ==
                      0 ? (
                        <>
                          <SelectPicker
                            data={containerOptions}
                            placeholder={name}
                            defaultValue={String(numberBags)}
                            searchable={false}
                            onChange={(e, event) => {
                              handleSelectChange(e, "setNumberBags");
                            }}
                            style={{ width: "100%" }}
                            cleanable={false}
                            value={String(numberBags)}
                          />
                          {formError["no_of_container"] &&
                          ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["no_of_container"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <InputGroup>
                            <InputNumber
                              className={"custom-input-number"}
                              value={String(numberBags)}
                              onChange={(e, event) => {
                                handleSelectChange(e, "setNumberBags");
                              }}
                              onKeyPress={disableKeys}
                              step={0.01}
                            />
                            {containerOptions &&
                              containerOptions.length != 0 && (
                                <Whisper
                                  className="rs-picker-toggle-caret"
                                  placement="bottomStart"
                                  trigger="click"
                                  speaker={numberContainerDropDown}
                                >
                                  <Button
                                    appearance="link"
                                    style={{ width: "40px", color: "#343a40" }}
                                  >
                                    <PageNextIcon rotate={90} />
                                  </Button>
                                </Whisper>
                              )}
                          </InputGroup>
                          {formError["no_of_container"] &&
                          ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["no_of_container"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                );
              })()}

            {/* numbers of Days */}
            {formulaDosagesSetting &&
              formulaDosagesSetting.no_of_days &&
              formulaDosagesSetting.no_of_days != 0 &&
              (() => {
                var name = formulaDosagesSetting.no_of_days_name
                  ? formulaDosagesSetting.no_of_days_name
                  : "Choose Number of Bags";

                return (
                  <div className="row mb-3">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      {decodeEntities(name)}
                      {formulaDosagesSetting.no_of_days_required == 1 && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <div className="col-sm-3">
                      {formulaDosagesSetting.no_of_days_allow_user_to_any_value ==
                      0 ? (
                        <>
                          <SelectPicker
                            data={dayOptions}
                            placeholder={name}
                            defaultValue={String(numberDays)}
                            searchable={false}
                            onChange={(e, event) => {
                              onNumberDaysChange(e);
                            }}
                            cleanable={false}
                            value={String(numberDays)}
                          />
                          {formError["no_of_days"] && ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["no_of_days"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <InputGroup>
                            <InputNumber
                              className={"custom-input-number"}
                              value={String(numberDays)}
                              onChange={(e, event) => {
                                onNumberDaysChange(e);
                              }}
                              onKeyPress={disableKeys}
                              step={0.01}
                            />
                            {dayOptions && dayOptions.length != 0 && (
                              <Whisper
                                className="rs-picker-toggle-caret"
                                placement="bottomStart"
                                trigger="click"
                                speaker={numberDaysDropDown}
                              >
                                <Button
                                  appearance="link"
                                  style={{ width: "40px", color: "#343a40" }}
                                >
                                  <PageNextIcon rotate={90} />
                                </Button>
                              </Whisper>
                            )}
                          </InputGroup>
                          {formError["no_of_days"] && ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["no_of_days"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                );
              })()}

            {/* Total Qty */}
            {formulaDosagesSetting &&
              formulaDosagesSetting.weight_volume &&
              formulaDosagesSetting.weight_volume !== "0" &&
              !(
                data.ingredient_selection_type ==
                  "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT" &&
                data.allow_multiple_measurement_types_in_same_formula === "1"
              ) &&
              !(
                data.ingredient_selection_type ==
                  "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES" &&
                data.selectedIngrediuents &&
                JSON.parse(data.selectedIngrediuents)[0]
                  ?.allow_multiple_measurement_types_in_same_formula === "1"
              ) &&
              (() => {
                var name = formulaDosagesSetting.weight_volume_name
                  ? formulaDosagesSetting.weight_volume_name
                  : "Total Quantity";
                return (
                  <div className="row mb-3">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      {decodeEntities(name)}
                      {formulaDosagesSetting.weight_volume_required == 1 && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <div className="col-sm-3">
                      {formulaDosagesSetting.weight_volume_allow_user_to_any_value ==
                      0 ? (
                        <>
                          <SelectPicker
                            data={totalQtyOptions}
                            placeholder={name}
                            defaultValue={String(totalQty)}
                            searchable={false}
                            onChange={(e, event) => {
                              setTotalQty(e);
                            }}
                            style={{ width: "90%" }}
                            cleanable={false}
                            value={String(totalQty)}
                          />
                          {formError["weight_volume"] && ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["weight_volume"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <InputGroup>
                            <InputNumber
                              value={String(totalQty)}
                              className={"custom-input-number"}
                              onChange={(e, event) => {
                                setTotalQty(e);
                              }}
                              style={{ width: "90%" }}
                              cleanable={false}
                            />
                            {totalQtyOptions && totalQtyOptions.length != 0 && (
                              <Whisper
                                className="rs-picker-toggle-caret"
                                placement="bottomStart"
                                trigger="click"
                                speaker={totalQtyDropDownder}
                              >
                                <Button
                                  appearance="link"
                                  style={{ width: "40px", color: "#343a40" }}
                                >
                                  <PageNextIcon rotate={90} />
                                </Button>
                              </Whisper>
                            )}
                          </InputGroup>
                          {formError["weight_volume"] && ApplyFormValidation ? (
                            <>
                              <ErrorMessage>
                                {formError["weight_volume"]}
                              </ErrorMessage>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                );
              })()}
          </div>

          <div className="col-4" style={{ font: "10px" }}></div>
        </div>
      </div>
    </>
  );
}
