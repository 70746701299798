import React, { useEffect, useState } from "react";
import styled from "styled-components";


const PopupContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 700px;
`;

const IngredientList = styled.ul`
list-style-type: none;
padding: 0;
font-size: 16px;
margin: 10px 18px;
text-align: left;
`;

const AdminMessage = styled.p`
font-size: 16px;
color: black;
margin-top: 20px;
padding: 10px;
border: 1px solid #ccc;
width: fit-content;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Buttons = styled.div`
margin-top: 45px;
display: flex;
justify-content: center;
gap: 10px;
`;

const Button = styled.button`
padding: 10px 20px;
border: 1px solid #ccc;
background-color: white;
font-size: 16px;
cursor: pointer;

&:hover {
  background-color: #f0f0f0;
}
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
`;

export default function IngredientPopup({
    outOfStockIngredients,
    data,
    dropdownStyle,
    onClose,
    onEditFormula,
  }){

    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        // Only show the popup if the ingredients are out of stock
        if (outOfStockIngredients.length > 0 && data.outofstock === "1") {
          setPopupVisible(true); // Show the popup
        }
      }, [outOfStockIngredients, data]);
    
      if (!popupVisible) return null;
      return (
        <PopupContainer>
          <PopupContent style={dropdownStyle}>
            <h2>The following Ingredients in your formula are out of stock:</h2>
            <IngredientList>
              {outOfStockIngredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </IngredientList>
            <CenteredContainer>
            <AdminMessage>
            {data?.outofstock_user_message || "An item in this formula is out of stock and cannot be purchased."}
            </AdminMessage>
            </CenteredContainer>
            <Buttons>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={onEditFormula}>Edit Formula</Button>
            </Buttons>
          </PopupContent>
        </PopupContainer>
      );
  }