import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, DatePicker, Form, SelectPicker } from "rsuite";

const JSONView = ({ formValue, formError }) => (
  <div className="row" style={{ marginBottom: 10 }}>
    <div class="col-8 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formValue</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class="col-4 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formError</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
);
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
  </>
);
const FormulaBuilderMarkupSection = ({
  formulaBuilderId,
  data,
  currency,
  onMarkupSectionChange,
  pracDetails,
  formulaDeatils,
  display_name,
  ApplyFormValidation,
  thisFormData,
  titleDisplayName
}) => {
  const { PercentageReducer } = useSelector((response) => response);
  const prac_formula_setting =
    pracDetails && pracDetails.formula_setting
      ? JSON.parse(pracDetails.formula_setting)
      : [];
  const current_formual_prac_setting =
    prac_formula_setting && prac_formula_setting[data.name]
      ? prac_formula_setting[data.name]
      : [];
  const [allowMarkup, setallowMarkup] = useState(data.markup || 0);
  const [markup_type, setmarkup_type] = useState(""); // flat,percentage
  const [markup_amount, setmarkup_amount] = useState(0);
  const [max_markup, setmax_markup] = useState(
    parseFloat(data.max_markup) || 0
  );
  const [max_markup_percentage, setmax_markup_percentage] = useState(
    parseFloat(data.max_markup_percentage) || 0
  );

  const [allow_max_markup, setMaxAllowMarkup] = useState(
    parseFloat(data.allowed_max_markup_price) || 0
  );

  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});

  useEffect(() => {
    if (pracDetails?.MARKUPS_CONTROL_SETTING != 1) {
      setallowMarkup(false);
    }
  }, [pracDetails]);

  var requirements = [
    {
      column: "allow_markup",
      name: "Allow Markup",
      is_required: 0,
      key: "allow_markup",
      allow_zero: true,
    },
    {
      column: "markup_type",
      name: "Markup Type",
      is_required: 0,
      key: "markup_type",
      allow_zero: false,
    },
    {
      column: "markup_amount",
      name: "Markup",
      is_required: 0,
      key: "markup_amount",
      allow_zero: true,
    },
    {
      column: "allowed_max_markup_price",
      name: "allowed_max_markup_price",
      is_required: 0,
      key: "allowed_max_markup_price",
      allow_zero: true,
    },
    {
      column: "max_markup_percentage",
      name: "max_markup_percentage",
      is_required: 0,
      key: "max_markup_percentage",
      allow_zero: true,
    },
  ];

  useEffect(() => {
    var markup_val = "";
    var type = "flat";
    if (current_formual_prac_setting) {
      markup_val = current_formual_prac_setting.markup
        ? current_formual_prac_setting.markup
        : markup_amount;
      setmarkup_amount(parseFloat(markup_val));

      type = current_formual_prac_setting.markup_type
        ? current_formual_prac_setting.markup_type
        : markup_type;
      setmarkup_type(type);
    }
    if (formulaDeatils && formulaDeatils.id) {
      var markup_val2 =
        formulaDeatils.markup_amount != 0 &&
        formulaDeatils.markup_amount != "" &&
        formulaDeatils.markup_amount != null &&
        formulaDeatils.markup_amount != undefined
          ? formulaDeatils.markup_amount
          : markup_amount;

      if (markup_val2 && markup_val2 != 0 && markup_val2 != "") {
        setmarkup_amount(parseFloat(markup_val2));

        var type = formulaDeatils.markup_type
          ? formulaDeatils.markup_type
          : markup_type;
        setmarkup_type(type);
      }
    }
  }, [formulaBuilderId, formulaDeatils]);

  useEffect(() => {
    if (markup_type === "flat") {
      if (max_markup > markup_amount) {
        // alert('max flat')
      }
    } else if (markup_type === "percentage") {
      if (max_markup_percentage > markup_amount) {
        // alert('max percentage')
      }
    }
    onMarkupSectionChange(allowMarkup, markup_type, Number(markup_amount));
    formValidation();
  }, [
    markup_type,
    markup_amount,
    max_markup,
    max_markup_percentage,
    allowMarkup,
    formulaBuilderId,
  ]);
  useEffect(() => {
    if (ApplyFormValidation == true) {
    }
  }, [ApplyFormValidation]);

  const formValidation = () => {
    formValue["allow_markup"] = allowMarkup;
    formValue["markup_type"] = markup_type;
    formValue["markup_amount"] = markup_amount;
    formValue["allowed_max_markup_price"] = allow_max_markup;
    formValue["max_markup_percentage"] = max_markup_percentage;

    var Final_array = [];
    Object.values(requirements).map((i, key) => {
      if (
        formValue["allow_markup"] != "" &&
        formValue["allow_markup"] != null &&
        formValue["allow_markup"] != undefined &&
        i.key == "markup_amount" &&
        formValue["markup_type"] != ""
      ) {
        i.is_required = 1;
      } else {
        formError["markup_type"] = "";
        formError["markup_amount"] = "";
      }
      if (i.is_required == 1) {
        if (i.allow_zero == true) {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = "This field is required";
          } else {
            formError[i.key] = "";
          }
        } else {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == 0 ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = "This field is required";
          } else {
            formError[i.key] = "";
          }
        }
      } else {
        formError[i.key] = "";
      }
      const temp = {
        ...i,
        value: formValue[i.key],
        error: formError[i.key],
      };
      Final_array.push(temp);
    });
    thisFormData(Final_array, "FORMULAS_MARKUP");
  };
  const disableKeys = (event) => {
    // Allow numbers, dots, and specific key codes for control keys
    if (
      !(event.key === "." || (event.key >= "0" && event.key <= "9")) &&
      ![8, 9, 37, 39, 46].includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };
  const updateDecimalPlaces = (value) => {
    if (value) {
      setmarkup_amount(parseFloat(value).toFixed(2));
    }
  };
  var option = [
    { label: "Choose Markup", value: "" },
    { label: "Fixed Amount", value: "flat" },
    { label: "Percentage", value: "percentage" },
  ];

  if (allowMarkup == 1) {
    return (
      <>
        <div className="FormulaBuilderMarkupSection">
          <h2 className="title mt-5">{display_name}</h2>
          <br />
          <div className="row FormulaBuilderMarkupSection">
            <div className="col-2">
              <SelectPicker
                placeholder={"Select Markup"}
                searchable={false}
                onChange={(e, event) => {
                  setmarkup_type(e);
                  setmarkup_amount(e === "");
                }}
                style={{ width: "100%" }}
                cleanable={false}
                value={String(markup_type)}
                data={option}
              />
            </div>
            {markup_type == "flat" ? (
              <div className="col-2" style={{ display: "inline-flex" }}>
                <span className="empty" style={{ padding: "6px" }}>
                  {currency}
                </span>

                <InputNumber
                  onChange={(e) => {
                    let inputValue = e.trim();
                    if (allow_max_markup && inputValue > allow_max_markup) {
                      inputValue = allow_max_markup;
                    }

                    var numericValue = parseFloat(inputValue);
                    if (max_markup) {
                      if (
                        inputValue === "" ||
                        (!isNaN(numericValue) && numericValue <= max_markup)
                      ) {
                        setmarkup_amount(
                          inputValue === "" ? null : numericValue
                        );
                      }
                    } else {
                      setmarkup_amount(inputValue === "" ? null : numericValue);
                    }
                  }}
                  value={String(markup_amount) || ""}
                  type="number"
                  name="markup_amount"
                  step={0.01}
                  onKeyPress={disableKeys}
                  onBlur={() => updateDecimalPlaces(markup_amount)}
                />
              </div>
            ) : null}

            {markup_type == "percentage" ? (
              <div className="col-2" style={{ display: "inline-flex" }}>
                {
                  <InputNumber
                    onChange={(e) => {
                      const inputValue = e.trim();
                      const numericValue = parseFloat(inputValue);
                      if (max_markup_percentage) {
                        if (
                          inputValue === "" ||
                          (!isNaN(numericValue) &&
                            numericValue <= max_markup_percentage)
                        ) {
                          setmarkup_amount(
                            inputValue === ""
                              ? null
                              : PercentageReducer.data === null
                              ? numericValue
                              : PercentageReducer.data
                          );
                        }
                      } else {
                        setmarkup_amount(
                          inputValue === "" ? null : numericValue
                        );
                      }
                    }}
                    value={
                      markup_amount === null
                        ? ""
                        : PercentageReducer.data === null
                        ? String(markup_amount)
                        : String(PercentageReducer.data)
                    }
                    type="number"
                    name="markup_amount"
                    step={0.01}
                    onKeyPress={disableKeys}
                    onBlur={() => updateDecimalPlaces(markup_amount)}
                  />
                }

                <span className="empty" style={{ padding: "6px" }}>
                  {" "}
                  %
                </span>
              </div>
            ) : null}
            <div className="col-3">
              {formError["markup_amount"] && ApplyFormValidation ? (
                <>
                  <ErrorMessage>{formError["markup_amount"]}</ErrorMessage>
                </>
              ) : null}
            </div>
          </div>

          <div style={{ marginBottom: "30px" }}></div>
        </div>
      </>
    );
  }
};

export default FormulaBuilderMarkupSection;
