import React, { useEffect, useState, useRef } from "react";
import { Form, Placeholder, SelectPicker } from "rsuite";
import { format } from "date-fns";
import Swal from "sweetalert2";
import "./style.css";
import InputForm from "../../../helpers/InputForm";
import { OptionYesNO } from "../../../helpers/common";
import PatientAddBtn from "../helpers/PatientAddBtn";
import styled from "styled-components";
const JSONView = ({ formValue, formError }) => {
  return (
    <div className="row" style={{ marginBottom: 10 }}>
      <div class="col-4 json-tree-wrapper rs-panel">
        <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
          <p class="rs-panel-title">formValue</p>
        </div>
        <div role="region" class="rs-panel-body">
          <pre>{JSON.stringify(formValue, null, 2)}</pre>
        </div>
      </div>
      <div class="col-8 json-tree-wrapper rs-panel">
        <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
          <p class="rs-panel-title">formError</p>
        </div>
        <div role="region" class="rs-panel-body">
          <pre>{JSON.stringify(formError, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span>*/}
  </>
);
const formatDate = (pattern) => {
  return format(new Date(), pattern);
};

export default function FormulaBuilderGeneralSection({
  formulaBuilderId,
  data,
  onGeneralSectionChange,
  pracDetails,
  formulaList,
  formulaDeatils,
  display_name,
  ApplyFormValidation,
  thisFormData,
  dosages_converting,
  formula_converting,
  is_order,
}) {
  const finalData = JSON.parse(data.general_section);
  const allow_converting = data.converting;
  const prac_formula_setting = pracDetails?.formula_setting
    ? JSON.parse(pracDetails.formula_setting)
    : [];
  const dateFormat = (pracDetails?.date_format || "y/M/d")
    .replace("d", "dd")
    .replace("m", "MM")
    .replace("y", "yy")
    .replace("Y", "yyyy");
  const currentPracSettings = prac_formula_setting?.[data.name] || [];
  const dosageModeArr = data.dosage_section
    ? JSON.parse(data.dosage_section)
    : [];
  const [dosageModes, setDosageModes] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [formulaBuilderType, setformulaBuilderType] =
    useState(formulaBuilderId);
  const [formulaBuilderName, setformulaBuilderName] = useState(null);
  const [formulaBuilderDecoction, setformulaBuilderDecoction] = useState(null);
  const [formulaBuilderPatient, setformulaBuilderPatient] = useState(null);
  const [formulaBuilderDosageMode, setformulaBuilderDosageMode] =
    useState(null);
  const [formulaBuilderShareFormula, setformulaBuilderShareFormula] =
    useState(null);
  const [formulaBuilderPractice, setformulaBuilderPractice] = useState(null);
  const [formulaBuilderSupervisor, setformulaBuilderSupervisor] =
    useState(null);
  const [formulaBuilderTemplate, setformulaBuilderTemplate] = useState(null);
  const [isStudent, setIsStudent] = useState(null);
  const [dosageConverting, setDosageConverting] = useState(false);
  const [formulaConverting, setFormulaConverting] = useState(false);
  const [formulaConvertingTo, setFormulaConvertingTo] = useState(null);
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});
  const pickerRef = useRef(null);
  var gernalSectionData = { ...finalData };
  var converting = { ...converting };
  const requirements = [
    {
      column: "ingredient_selection_type",
      name: "Ingredient Selection Type",
      is_required: 0,
      key: "ingredient_selection_type",
      allow_zero: false,
    },
  ];

  const fontStyle = {
    fontFamily: data?.text_font ? data.text_font : "inherit",
  };
  // Populate requirements based on active sections
  var newData = [];
  useEffect(() => {
    Object.entries(gernalSectionData).forEach(([key, value]) => {
      if (value.is_active) {
        const allowZero = [
          "FORMULA_SECTION_DECOCTION",
          "FORMULA_SECTION_PRACTICE",
        ].some((item) => value.key_value.includes(item));
        newData.push({
          column: value.name,
          name: value.display_name,
          is_required: value.is_required,
          key: value.key_value,
          allow_zero: allowZero,
        });
      }
    });
  }, [gernalSectionData]);

  // Update data according to Prac Formula Setting
  useEffect(() => {
    const {
      dosages_modes,
      default_formula_name, // custom_name,blank_name,today_date
      default_formula_name_value,
      formula_privacy_value,
      decoction_default,
      default_dosages_mode,
    } = currentPracSettings || {};

    const prac_user_type = pracDetails?.prac_user_type || "practitioner";
    const textArray = dosages_modes?.split(",") || [];
    var newData = [];
    if (dosageModeArr?.length != 0) {
      Object.values(dosageModeArr).map((item) => {
        var key = item.key_value;
        if (textArray.includes(key) && item.is_active == 1) {
          newData.push(item);
        }
      });
    }
    setDosageModes(newData.length > 0 ? newData : dosageModeArr);

    // Default values
    let name = formulaBuilderName || "";
    let privacy_value = formulaBuilderShareFormula || "shared";
    let decoction = formulaBuilderDecoction || "0";
    let selected_dosage_type = formulaBuilderDosageMode || "";
    let patient = formulaBuilderPatient || "";
    let supervisor_list = [];
    let selected_supervisor = formulaBuilderSupervisor || "";
    let student = isStudent || false;
    let template = formulaBuilderTemplate || "";
    let practice = formulaBuilderPractice || "";

    // Update Prac Setting
    if (formulaBuilderName == null || formulaBuilderName === "") {
      const type = default_formula_name || "blank_name";
      if (type === "today_date") {
        name = formatDate(dateFormat);
      } else if (type === "custom_name") {
        name = default_formula_name_value || "";
      }
    }
    if (currentPracSettings && formulaBuilderShareFormula == null) {
      privacy_value =
        formula_privacy_value != "" &&
        formula_privacy_value != null &&
        formula_privacy_value != 0
          ? formula_privacy_value
          : privacy_value;
    }
    if (currentPracSettings && formulaBuilderDecoction == null) {
      decoction = decoction_default ? decoction_default : 0;
    }
    if (currentPracSettings && isStudent == null) {
      student = prac_user_type == "student" ? true : false;
      if (student == true) {
        supervisor_list =
          pracDetails && pracDetails.supervisor
            ? JSON.parse(pracDetails.supervisor)
            : [];
      }
    }
    if (currentPracSettings && formulaBuilderDosageMode == null) {
      var thisValKey =
        default_dosages_mode != ""
          ? default_dosages_mode
          : formulaBuilderDosageMode;

      if (thisValKey != 0 && thisValKey != undefined) {
        selected_dosage_type =
          dosageModeArr && dosageModeArr[thisValKey]?.["is_active"] == 1
            ? dosageModeArr[thisValKey].id
            : "";
        if (selected_dosage_type == "") {
          Object.values(dosageModes).map((i, k) => {
            if (k == 0) {
              selected_dosage_type = i.id;
            }
          });
        }
      } else {
        Object.values(dosageModes).map((i, k) => {
          if (k == 0) {
            selected_dosage_type = i.id;
          }
        });
      }
    }

    // Check if formulaDeatils is available before accessing its properties
    if (formulaDeatils?.id) {
      name = formulaDeatils.name || name;
      decoction = formulaDeatils.is_decocation || decoction;
      patient =
        formulaDeatils.patient_id && formulaDeatils.patient_id != 0
          ? formulaDeatils.patient_id
          : formulaBuilderPatient;
      selected_dosage_type = formulaDeatils.formula_t || selected_dosage_type;
      privacy_value = formulaDeatils.formula_state || privacy_value;
      selected_supervisor = formulaDeatils.supervisor || selected_supervisor;
      template = formulaDeatils.template || formulaBuilderTemplate;
      practice = formulaDeatils.practice || formulaBuilderPractice;
    }

    // update states
    setTimeout(() => {
      setformulaBuilderName(name);
      setformulaBuilderShareFormula(privacy_value);
      setformulaBuilderDecoction(decoction);
      setSupervisorList(supervisor_list);
      setformulaBuilderSupervisor(selected_supervisor);
      setIsStudent(student);
      setformulaBuilderDosageMode(selected_dosage_type);
      setformulaBuilderPatient(patient);
      setformulaBuilderPractice(practice);
      setformulaBuilderTemplate(template);
    }, 200);
  }, [formulaBuilderId, formulaDeatils]);

  useEffect(() => {
    onGeneralSectionChange(
      formulaBuilderType,
      formulaBuilderName,
      formulaBuilderDecoction,
      formulaBuilderPatient,
      formulaBuilderDosageMode,
      formulaBuilderShareFormula,
      formulaBuilderPractice,
      formulaBuilderSupervisor,
      formulaBuilderTemplate,
      formulaConverting,
      formulaConvertingTo
    );
    if (ApplyFormValidation) {
      formValidation();
    }
  }, [
    formulaBuilderType,
    formulaBuilderName,
    formulaBuilderDecoction,
    formulaBuilderPatient,
    formulaBuilderDosageMode,
    formulaBuilderShareFormula,
    formulaBuilderPractice,
    formulaBuilderSupervisor,
    formulaBuilderTemplate,
    formulaConverting,
    formulaConvertingTo,
    ApplyFormValidation,
  ]);

  const formValidation = () => {
    formValue["FORMULA_SECTION_DOSAGE"] = formulaBuilderDosageMode;
    formValue["FORMULA_SECTION_SHARE_FORMULA"] = formulaBuilderShareFormula;
    formValue["FORMULA_SECTION_DECOCTION"] = formulaBuilderDecoction;
    formValue["FORMULA_SECTION_PRACTICE"] = formulaBuilderPractice;
    formValue["FORMULA_SECTION_SUPERVISOR"] = formulaBuilderSupervisor;
    formValue["FORMULA_SECTION_TEMPLATE"] = formulaBuilderTemplate;
    formValue["FORMULA_SECTION_FORMULA_NAME"] = formulaBuilderName;
    formValue["FORMULA_SECTION_PATIENT_NAME"] = formulaBuilderPatient;
    formValue["FORMULA_SECTION_HERB_TYPE"] = formulaBuilderType;
    formValue["ingredient_selection_type"] = data?.ingredient_selection_type;

    var Final_array = [];
    Object.values(requirements).map((i, key) => {
      if (i.is_required == 1 && i.key != "FORMULA_SECTION_ADD_PATIENT_BUTTON") {
        if (i.allow_zero == true) {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = `${i.name} is required.`;
          } else {
            formError[i.key] = "";
          }
        } else {
          if (
            String(formValue[i.key]).trim() == "" ||
            formValue[i.key] == null ||
            formValue[i.key] == 0 ||
            formValue[i.key] == undefined
          ) {
            formError[i.key] = `${i.name} is required.`;
          } else {
            formError[i.key] = "";
          }
        }
      } else {
        formError[i.key] = "";
      }

      var temp = {
        ...i,
        error: formError[i.key],
        value: formValue[i.key],
      };
      Final_array.push(temp);
    });
    thisFormData(Final_array, "FORMULAS_GENERAL_DETAILS");
  };

  const handleGeneralSectionChange = async (
    value,
    field,
    column,
    column_name = "formula type"
  ) => {
    if (column == "FORMULA_SECTION_HERB_TYPE" && formulaBuilderId != value) {
      var allowthisFormulaConveting =
        allow_converting && allow_converting[value]
          ? allow_converting[value]
          : 0;
      //return false
      if (allowthisFormulaConveting == 1) {
        const allow = await Swal.fire({
          title: "Are you sure?",
          text: "you want to change " + column_name + " ? ",
          icon: "warning",
          allowOutsideClick: false,
          timer: 10000,
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, change it! ",
          cancelButtonText: "Cancel",
        });

        if (allow.isConfirmed) {
          setFormulaConverting(true);
          setFormulaConvertingTo(value);
          value = formulaBuilderId;
        } else {
          value = formulaBuilderId;
        }
      } else {
        alert(column_name + " could not convert to selected formula type");
        value = formulaBuilderId;
        setFormulaConvertingTo(null);
      }
    }
    const func = eval(field);
    if (func) {
      func(value);
    }
    if (column == "FORMULA_SECTION_DOSAGE") {
      setDosageConverting(true);
      setTimeout(() => {
        setDosageConverting(false);
      }, 1000);
    }

    formValidation();
  };

  // Converting
  useEffect(() => {
    dosages_converting(dosageConverting);
  }, [dosageConverting]);

  useEffect(() => {
    formula_converting(formulaConverting);
  }, [formulaConverting]);

  if (finalData.length == 0) {
    return <Placeholder.Paragraph rows={6} />;
  }

  return (
    <div className="FormulaBuilderGeneralSection">
      <h2 className="title mt-5">{display_name}</h2>
      <div className="row" style={{ marginBottom: "1rem" }}>
        <div className="col-8">
          {Object.entries(gernalSectionData).map(([key, value]) => {
            var thisField = [];
            thisField = value;
            if (value.field_type === "select") {
              const getDynamicStyles = {
                fontSize: value.input_font_size
                  ? `${value.input_font_size}px`
                  : "12px",
                color: value.input_font_color,
                backgroundColor: value.input_bg_color,
                borderColor: value.input_border_color,
                borderWidth: value.input_border_size
                  ? `${value.input_border_size}px`
                  : "2px",
                position: value.position,
              };

              // Differentiate logic and data based on key_value
              switch (value.key_value) {
                case "FORMULA_SECTION_HERB_TYPE":
                  thisField.options = formulaList;
                  thisField.optionsValueField = "id";
                  thisField.optionsLabelField = "display_name";
                  thisField.triggerName = "setformulaBuilderType";
                  thisField.selectedValue = formulaBuilderType;
                  break;

                case "FORMULA_SECTION_DECOCTION":
                  thisField.options = OptionYesNO();
                  thisField.optionsValueField = "value";
                  thisField.optionsLabelField = "label";
                  thisField.triggerName = "setformulaBuilderDecoction";
                  thisField.selectedValue = formulaBuilderDecoction;
                  thisField.display_name = value.display_name;
                  break;

                case "FORMULA_SECTION_PATIENT_NAME":
                  const options = JSON.parse(data.all_patient_list).map(
                    (item) => ({
                      firstname: `${item.firstname} ${item.lastname}`,
                      id: item.id,
                    })
                  );
                  thisField.options = options;
                  thisField.optionsValueField = "id";
                  thisField.optionsLabelField = "firstname";
                  thisField.triggerName = "setformulaBuilderPatient";
                  thisField.selectedValue = formulaBuilderPatient;
                  break;

                case "FORMULA_SECTION_DOSAGE":
                  const activeDosageModes = dosageModes
                    ? Object.values(dosageModes).filter((v) => v.is_active == 1)
                    : [];
                  thisField.options = activeDosageModes;
                  thisField.optionsValueField = "id";
                  thisField.optionsLabelField = "display_name";
                  thisField.triggerName = "setformulaBuilderDosageMode";
                  thisField.selectedValue = formulaBuilderDosageMode;
                  // Set default selected value if it's null or 0
                  if (
                    formulaBuilderDosageMode == null ||
                    formulaBuilderDosageMode == 0
                  ) {
                    const firstActiveDosageMode = activeDosageModes[0];
                    if (firstActiveDosageMode) {
                      setformulaBuilderDosageMode(firstActiveDosageMode.id);
                      thisField.selectedValue = firstActiveDosageMode.id;
                    }
                  }
                  break;

                case "FORMULA_SECTION_SHARE_FORMULA":
                  thisField.options = [
                    { value: "shared", label: "Yes" },
                    { value: "private", label: "No" },
                  ];
                  thisField.optionsValueField = "value";
                  thisField.optionsLabelField = "label";
                  thisField.triggerName = "setformulaBuilderShareFormula";
                  thisField.selectedValue = formulaBuilderShareFormula;
                  break;

                case "FORMULA_SECTION_PRACTICE":
                  thisField.options = OptionYesNO();
                  thisField.optionsValueField = "value";
                  thisField.optionsLabelField = "label";
                  thisField.triggerName = "setformulaBuilderPractice";
                  thisField.selectedValue = formulaBuilderPractice;
                  break;

                case "FORMULA_SECTION_SUPERVISOR":
                  thisField.options = supervisorList;
                  thisField.optionsValueField = "id";
                  thisField.optionsLabelField = "name";
                  thisField.triggerName = "setformulaBuilderSupervisor";
                  thisField.selectedValue = formulaBuilderSupervisor;
                  thisField.is_active =
                    pracDetails.prac_user_type !== "student"
                      ? false
                      : value.is_active;
                  break;

                case "FORMULA_SECTION_TEMPLATE":
                  thisField.options = [];
                  thisField.optionsValueField = "id";
                  thisField.optionsLabelField = "display_name";
                  thisField.triggerName = "setformulaBuilderTemplate";
                  thisField.selectedValue = formulaBuilderTemplate;
                  break;

                default:
                  console.warn(`Unhandled key_value: ${value.key_value}`);
              }

              var patientBtn = [];
              var searchable =
                value.key_value == "FORMULA_SECTION_PATIENT_NAME"
                  ? true
                  : false;
              var cleanable =
                value.key_value == "FORMULA_SECTION_PATIENT_NAME"
                  ? true
                  : false;

              if (value.key_value === "FORMULA_SECTION_PATIENT_NAME") {
                var patientBtn = Object.entries(gernalSectionData).find(
                  ([key, value1]) =>
                    value1.key_value == "FORMULA_SECTION_ADD_PATIENT_BUTTON"
                );
              }
              // Initialize optionData as an empty array
              var optionData = [];
              optionData = Object.values(thisField.options)
                ? Object.values(thisField.options).map((item) => ({
                    label: item[thisField.optionsLabelField],
                    value: String(item[thisField.optionsValueField]),
                  }))
                : [];

              // sort patient list data
              if (thisField.key_value === "FORMULA_SECTION_PATIENT_NAME") {
                optionData.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
              }

              if (thisField.is_active != 1) {
                return "";
              }
              var width =
                thisField.key_value === "FORMULA_SECTION_PATIENT_NAME" &&
                thisField.width == "0"
                  ? "70%"
                  : thisField.width || thisField.width == "0"
                  ? `${thisField.width}px`
                  : "100%";
              var error = formError[thisField.key_value];
              const is_required = thisField.is_required == 1 ? "required" : "";
              const keysThatNeedStyles = [
                "FORMULA_SECTION_PATIENT_NAME",
                "FORMULA_SECTION_PRACTICE",
                "FORMULA_SECTION_SUPERVISOR",
                "FORMULA_SECTION_SHARE_FORMULA",
                "FORMULA_SECTION_TEMPLATE",
                "FORMULA_SECTION_DECOCTION",
                "FORMULA_SECTION_HERB_TYPE",
                "FORMULA_SECTION_DOSAGE", // Add more as needed
              ];
              const labelStyle = {
                fontSize: value.field_font_size
                  ? `${value.field_font_size}px`
                  : "12px",
                color: value.field_font_color || "gray",
                textDecoration:
                  value.field_underline == "1" ? "underline" : "none",
                fontWeight: value.field_bold == "1" ? "bold" : "normal",
                backgroundColor: value.field_highlight_color,
                display: "inline-block",
                whiteSpace: "nowrap",
              };

              const dropdownStyle = {
                ...getDynamicStyles,
                backgroundColor: value?.input_dp_bg_color || "transparent",
              };
              const margin = {
                marginTop: value.field_margin_top
                  ? `${value.field_margin_top}px`
                  : "0px",
                marginRight: value.field_margin_right
                  ? `${value.field_margin_right}px`
                  : "0px",
                marginLeft: value.field_margin_left
                  ? `${value.field_margin_left}px`
                  : "0px",
                marginBottom: value.field_margin_bottom
                  ? `${value.field_margin_bottom}px`
                  : "0px",
              };

              // Generate dynamic options for the SelectPicker
              return (
                <>
                  <div className="row mb-3" style={margin}>
                    <label for="" className="col-sm-4 col-form-label">
                      <span style={labelStyle}>{thisField.display_name}</span>
                      {thisField.is_required == 1 && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <div className="col-sm-8 formula-drop-down">
                      <Form.Group
                        className={error ? "has-error" : ""}
                        style={{ width: width }}
                      >
                        <SelectPicker
                          data={optionData}
                          ref={pickerRef}
                          style={
                            keysThatNeedStyles.includes(thisField.key_value)
                              ? getDynamicStyles
                              : ""
                          }
                          placeholder={
                            thisField.field_placeholder != ""
                              ? thisField.field_placeholder
                              : "Choose "
                          }
                          defaultValue={String(thisField.selectedValue)}
                          searchable={searchable}
                          onChange={(e) => {
                            if (e == null) {
                              var e = "";
                            }
                            handleGeneralSectionChange(
                              e,
                              thisField.triggerName,
                              thisField.key_value,
                              thisField.display_name
                            );
                          }}
                          name={thisField.key_value}
                          cleanable={cleanable}
                          labelKey={"label"}
                          valueKey={"value"}
                          value={String(thisField.selectedValue)}
                          errorMessage={formError[thisField.key_value]}
                          required={is_required}
                          readOnly={
                            is_order == "true" &&
                            thisField.key_value == "FORMULA_SECTION_HERB_TYPE"
                              ? true
                              : false
                          }
                          renderMenuItem={(label, item) => {
                            const handleMouseOver = (e) => {
                              e.target.style.backgroundColor =
                                value?.input_dp_hover_color ||
                                dropdownStyle.backgroundColor;
                              e.target.style.color =
                                value?.input_dp_hover_text_color ||
                                dropdownStyle.color;
                            };

                            const handleMouseOut = (e) => {
                              e.target.style.backgroundColor =
                                dropdownStyle.backgroundColor;
                              e.target.style.color = dropdownStyle.color;
                            };
                            const menuItemStyle = {
                              ...dropdownStyle,
                              ...fontStyle,
                            };

                            return (
                              <div
                                key={item.value}
                                style={menuItemStyle}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                {label}
                              </div>
                            );
                          }}
                          renderValue={(value) => {
                            const selectedOption = optionData.find(
                              (option) => option.value === value
                            );
                            return (
                              <div style={getDynamicStyles}>
                                {selectedOption
                                  ? selectedOption.label
                                  : thisField.field_placeholder}
                              </div>
                            );
                          }}
                          classPrefix={"formula-name-picker"}
                        />
                        {formError[thisField.key_value] &&
                        ApplyFormValidation ? (
                          <>
                            <ErrorMessage>
                              {formError[thisField.key_value]}
                            </ErrorMessage>
                          </>
                        ) : null}
                      </Form.Group>
                      {thisField.key_value === "FORMULA_SECTION_PATIENT_NAME"
                        ? patientBtn &&
                          patientBtn.length !== 0 && (
                            <PatientAddBtn value={patientBtn} />
                          )
                        : ""}
                    </div>
                  </div>
                </>
              );
            } else if (
              value.field_type === "text" &&
              value.key_value == "FORMULA_SECTION_FORMULA_NAME"
            ) {
              value.triggerName = "setformulaBuilderName";
              value.selectedValue = formulaBuilderName;
              var error =
                ApplyFormValidation && formError[thisField.key_value]
                  ? formError[thisField.key_value]
                  : "";
              const handleTextChange = (e) => {
                let textValue = e;
                const maxLength = parseInt(value.max_char, 10);
                if (
                  maxLength &&
                  maxLength !== 0 &&
                  textValue.length > maxLength
                ) {
                  textValue = textValue.slice(0, maxLength);
                }
                handleGeneralSectionChange(
                  textValue,
                  value.triggerName,
                  thisField.key_value
                );
              };
              return (
                <>
                  <InputForm
                    value={value}
                    onChange={handleTextChange}
                    name={thisField.key_value}
                    error={error}
                  />
                </>
              );
            }
          })}
        </div>
        <div className='col-4'>
          {/* <JSONView formValue={formValue} formError={formError} /> */}
        </div>
      </div>
    </div>
  );
}
