import React from "react";
import {
  Drawer,
  ButtonToolbar,
  Button,
  Placeholder,
  SelectPicker,
  Form,
  Schema,
  Panel,
  Message,
  toaster,
  Input,
  InputGroup,
  Whisper,
  Tooltip,
} from "rsuite";
import InfoRoundIcon from "@rsuite/icons/InfoRound";

const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
);

function InputForm({
  value,
  onChange = null, // on change option trigger
  error = "",
}) {
  var visibility = value.is_active;
  const is_active = visibility == 1 ? "" : "none";
  const ballStyle = {
    padding: "6px",
    borderColor: value.input_border_color,
    borderWidth: value.input_border_size
      ? `${value.input_border_size}px`
      : "2px",
    backgroundColor: value.input_bg_color,
    fontSize: value.input_font_size ? `${value.input_font_size}px` : "12px",
    color: value.field_font_color || "gray",
  };

  const labelStyle = {
    fontSize: value.field_font_size ? `${value.field_font_size}px` : "12px",
    whiteSpace: "nowrap",
    color: value.field_font_color || "gray",
    textDecoration: value.field_underline == "1" ? "underline" : "none",
    fontWeight: value.field_bold == "1" ? "bold" : "normal",
    backgroundColor: value.field_highlight_color,
    display: "inline-block",
  };
  const margin = {
    marginTop: value.field_margin_top ? `${value.field_margin_top}px` : "0px",
    marginRight: value.field_margin_right
      ? `${value.field_margin_right}px`
      : "0px",
    marginLeft: value.field_margin_left
      ? `${value.field_margin_left}px`
      : "0px",
    marginBottom: value.field_margin_bottom
      ? `${value.field_margin_bottom}px`
      : "0px",
  };

  const handleSelectChange = (value, field) => {
    onChange(value, field);
  };
  const triggerName = value.triggerName;
  const Hidden = { display: is_active };
  var requried = value.is_required;
  const is_required = requried == 1 ? "required" : "";

  if (value.is_active != 1) {
    return null; // or return undefined;
  }

  var width = value.width || value.width == "0" ? `${value.width}px` : "100%";

  return (
    <>
      <div className="row mb-3" style={margin}>
        <label htmlFor={value.field_name} className="col-sm-4 col-form-label">
          <span style={labelStyle}>{value.display_name}</span>
          {requried == 1 && <span className="required">*</span>}
        </label>
        <div className="col-sm-8">
          <Form.Group
            className={error ? "has-error" : ""}
            style={{ width: width }}
          >
            <InputGroup inside>
              {is_required ? (
                <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
                  <Input
                    style={ballStyle}
                    type="text"
                    name={value.key_value}
                    placeholder={value.field_placeholder}
                    value={value.selectedValue}
                    onChange={(e) => {
                      handleSelectChange(e, triggerName);
                    }}
                    required={is_required}
                    className="styled-input"
                  />
                </Whisper>
              ) : (
                <Input
                  style={ballStyle}
                  type="text"
                  name={value.key_value}
                  placeholder={value.field_placeholder}
                  value={value.selectedValue}
                  onChange={(e) => {
                    handleSelectChange(e, triggerName);
                  }}
                  required={is_required}
                  className="styled-input"
                />
              )}

              {/* <InputGroup.Addon>
                <Whisper placement="top" speaker={<Tooltip> Required</Tooltip>}>
                  <InfoRoundIcon />
                </Whisper>
              </InputGroup.Addon> */}
            </InputGroup>
            {error ? (
              <>
                <ErrorMessage>{error}</ErrorMessage>
              </>
            ) : null}
          </Form.Group>
        </div>
      </div>
      {/* Global styles for placeholder */}
      <style>
        {`
          .styled-input::placeholder {
            color: ${ballStyle.color};
            font-size: ${ballStyle.fontSize}
          }
        `}
      </style>
    </>
  );
}

export default InputForm;
