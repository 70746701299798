import React, { useEffect, useState } from 'react'

const FormulaBuilderSummarySection = ({
  currency,
  formulaSummary,
  display_name,
  allowEnterQty,
  allowRawQty,
  ingredients
}) => {
  const decimalPlaceMap = {};
  ingredients.forEach((ingredient) => {
    decimalPlaceMap[ingredient.formula_dosage_column_form] = ingredient.final_decimal_place;
  });
  return (
    <>
      <div className="FormulaBuilderSummarySection">
        {formulaSummary && Object.keys(formulaSummary).length > 0 && (
          <h2 className="title mt-5">{display_name}</h2>
        )}

        <div className="col-4">
          {formulaSummary &&
            Object.keys(formulaSummary).map((ingredientType, measurement) => {
              const this_decimal = decimalPlaceMap[ingredientType];
              return (
                <table
                  className="table TableMainadd table-bordered"
                  style={{ overflowX: "unset" }}
                >
                  <thead>
                    <tr>
                      <th className="column" style={{ width: "auto" }}>
                        {ingredientType}
                      </th>
                      {Object.keys(formulaSummary[ingredientType]).map(
                        (measurement) => (
                          <th style={{ width: "100px" }} key={measurement}>
                            {measurement}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`tbody ${ingredientType}`}
                    key={ingredientType}
                  >
                    {formulaSummary[ingredientType].quantitySelected !== 0 && (
                      <>
                        {/* Ingredients Quantity Row */}
                        <tr>
                          <td className="column">Number of Ingredients</td>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement) =>
                              measurement !== "quantitySelected" && (
                                <React.Fragment key={measurement}>
                                  <td>
                                    {
                                      formulaSummary[ingredientType][
                                        measurement
                                      ].quantitySelected
                                    }
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                        {/*Enter Qty*/}
                        {allowEnterQty && (
                          <tr>
                            {Object.keys(formulaSummary[ingredientType]).map(
                              (measurement, index) =>
                                measurement !== "quantitySelected" && (
                                  <React.Fragment key={measurement}>
                                    {index %
                                      Object.keys(
                                        formulaSummary[ingredientType]
                                      ).length ===
                                      0 && (
                                      <td className="column">
                                        Total{" "}
                                        {
                                          formulaSummary[ingredientType][
                                            measurement
                                          ].qtyValueColumnName
                                        }{" "}
                                        Entered
                                      </td>
                                    )}
                                    <td>
                                      {formulaSummary[ingredientType][
                                        measurement
                                      ].totalQty.toFixed(this_decimal)}
                                    </td>
                                  </React.Fragment>
                                )
                            )}
                          </tr>
                        )}

                        {/*Raw Qty*/}
                        {allowRawQty && (
                          <tr>
                            {Object.keys(formulaSummary[ingredientType]).map(
                              (measurement, index) =>
                                measurement !== "quantitySelected" && (
                                  <React.Fragment key={measurement}>
                                    {index %
                                      Object.keys(
                                        formulaSummary[ingredientType]
                                      ).length ===
                                      0 && (
                                      <td className="column">
                                        Total{" "}
                                        {
                                          formulaSummary[ingredientType][
                                            measurement
                                          ].rawPerDayColumnName
                                        }{" "}
                                        Entered
                                      </td>
                                    )}
                                    <td>
                                      {formulaSummary[ingredientType][
                                        measurement
                                      ].rawPerDay.toFixed(this_decimal)}
                                    </td>
                                  </React.Fragment>
                                )
                            )}
                          </tr>
                        )}
                        <tr>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement, index) =>
                              measurement !== "quantitySelected" && (
                                <React.Fragment key={measurement}>
                                  {index %
                                    Object.keys(formulaSummary[ingredientType])
                                      .length ===
                                    0 && (
                                    <td
                                      key={measurement}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {"Total Amount Received"}
                                    </td>
                                  )}
                                  <td>
                                    {formulaSummary[ingredientType][
                                      measurement
                                    ].totalQtyReceived.toFixed(
                                      this_decimal
                                    )}{" "}
                                    {measurement}
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                        <tr>
                          <td className="column">Subtotal</td>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement) =>
                              measurement !== "quantitySelected" && (
                                <td key={measurement}>
                                  {currency}
                                  {formulaSummary[ingredientType][
                                    measurement
                                  ].subtotal.toFixed(2)}
                                </td>
                              )
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              );
            })}
        </div>
        <div style={{ marginBottom: "30px" }}></div>
      </div>
    </>
  );
}

export default FormulaBuilderSummarySection
